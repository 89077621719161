"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryPromise = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const DeliveryPromiseMessage_1 = require("../../../delivery-promise/DeliveryPromiseMessage");
const BasketSelector_1 = require("../../../../redux/basket/selectors/BasketSelector");
const ComponentConfigContext_1 = require("../../../../context/ComponentConfigContext");
const useFeatureToggle_1 = require("../../../../hooks/useFeatureToggle");
const DeliveryPromiseUtil_1 = require("../../../../util/DeliveryPromiseUtil");
var DeliveryDateEnum;
(function (DeliveryDateEnum) {
    DeliveryDateEnum["HOURS"] = "H";
    DeliveryDateEnum["DAYS"] = "D";
    DeliveryDateEnum["WEEKS"] = "W";
})(DeliveryDateEnum || (DeliveryDateEnum = {}));
const NO_DELIVERY_MESSAGE = '0-0';
const dataTestId = 'basket_delivery_promise';
const getDeliveryPromiseStatus = (hasCutoff, option, deliveryUnit) => {
    if (hasCutoff) {
        return 'direct';
    }
    if ([common_types_1.DeliveryOptionsEnum.NEXT_DAY, common_types_1.DeliveryOptionsEnum.NAMED_DAY].includes(option)) {
        return 'direct';
    }
    if ([common_types_1.DeliveryOptionsEnum.COLLECT_STORE, common_types_1.DeliveryOptionsEnum.COLLECT_HQ].includes(option) &&
        deliveryUnit === DeliveryDateEnum.HOURS) {
        return 'direct';
    }
    return 'delayed';
};
const DeliveryPromise = ({ deliveryStoreName, isCheckoutFlowDisabled, handleChangeStore, hideEvoucherMessage, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.buy, i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.product]);
    const delivery_promise_standard_delivery_label = (0, useFeatureToggle_1.useFeatureToggle)('delivery_promise_standard_delivery_label');
    const { totalMiniBlock } = (0, ComponentConfigContext_1.useBasketOverviewConfig)();
    const { delivery, deliveryMessages, deliveryUnit, hasEvoucher, requiresDelivery, deliveryPromiseCutoff } = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDeliveryInfoState)());
    const orderLinesHaveDifferentDeliveryPromises = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketDifferentDeliveryPromisesState)());
    const getTitleForFallback = () => {
        const deliveryTime = delivery.split('-');
        let deliveryUnitTranslate = '';
        if (deliveryUnit) {
            switch (deliveryUnit) {
                case DeliveryDateEnum.HOURS:
                    deliveryUnitTranslate = t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt.hours`);
                    break;
                case DeliveryDateEnum.DAYS:
                    deliveryUnitTranslate = t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt.days`);
                    break;
                case DeliveryDateEnum.WEEKS:
                    deliveryUnitTranslate = t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt.weeks`);
                    break;
                default:
                    deliveryUnitTranslate = '';
            }
        }
        return (t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.size.deliverypromise.txt`, {
            deliveryFrom: deliveryTime[0],
            deliveryTo: deliveryTime[1],
        }) +
            ' ' +
            deliveryUnitTranslate);
    };
    const getTitle = (key) => delivery_promise_standard_delivery_label
        ? `${t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.promise.` + key)}${deliveryMessages[key]}`
        : deliveryMessages[key];
    const getDeliveryText = () => orderLinesHaveDifferentDeliveryPromises && (totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.deliveryTimeText) ? ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "2", paddingInlineStart: "6", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: totalMiniBlock === null || totalMiniBlock === void 0 ? void 0 : totalMiniBlock.deliveryTimeText }) })) : null;
    const deliveryPromiseCtaProps = deliveryStoreName && handleChangeStore
        ? {
            text: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.promise.change.store`),
            prefix: `${deliveryStoreName} -`,
            onClick: handleChangeStore,
        }
        : undefined;
    if (isCheckoutFlowDisabled) {
        return ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.DeliveryPromiseMessage, { touchPoint: "pdp+basket", state: "unavailable", option: "ccSpecific", dataTestId: dataTestId, message: t(`${i18nConstants_1.I18N_NAMESPACES.buy}:buy.delivery.promise.cannot.be.collected`), cta: deliveryPromiseCtaProps }), getDeliveryText()] }));
    }
    if (delivery === NO_DELIVERY_MESSAGE) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!deliveryMessages && requiresDelivery && ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.DeliveryPromiseMessage, { touchPoint: "pdp+basket", state: "direct", option: "ccSpecific", dataTestId: dataTestId, message: getTitleForFallback(), cta: deliveryPromiseCtaProps }), getDeliveryText()] })), deliveryMessages &&
                requiresDelivery &&
                Object.entries(deliveryMessages).map(([deliveryOption, deliveryMessage]) => ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.DeliveryPromiseMessage, { touchPoint: "pdp+basket", state: getDeliveryPromiseStatus(Boolean(deliveryPromiseCutoff), deliveryOption, deliveryUnit), type: deliveryOption, option: (0, DeliveryPromiseUtil_1.isHomeDelivery)(deliveryOption) ? 'hd' : 'ccSpecific', dataTestId: dataTestId, cutoff: deliveryPromiseCutoff, message: deliveryPromiseCutoff ? deliveryMessage : getTitle(deliveryOption), cta: deliveryPromiseCtaProps }), getDeliveryText()] }, deliveryOption))), hasEvoucher && !hideEvoucherMessage && ((0, jsx_runtime_1.jsx)(DeliveryPromiseMessage_1.DeliveryPromiseMessage, { touchPoint: "pdp+basket", state: "direct", option: "hd", dataTestId: dataTestId, message: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.mailbox`) }))] }));
};
exports.DeliveryPromise = DeliveryPromise;
