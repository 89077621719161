"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileSizesInfo = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const ProductUtil_1 = require("../../../../util/ProductUtil");
const SIZES_MAX = 5;
const SIZE_ROW_LENGTH = 20;
const getSizeDescription = (size) => size.fullCommercialSize || size.commercialSizeDefault || size.code;
const ProductTileSizesInfo = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { activeColorId, colors } = (0, cotopaxi_1.useProductTileContext)();
    const activeColour = colors.find(color => color.colourId === activeColorId);
    const { sizes = [] } = activeColour;
    const filteredSizes = (0, ProductUtil_1.getAvailableSizes)(sizes);
    if (filteredSizes.length > SIZES_MAX ||
        filteredSizes.map(size => getSizeDescription(size)).join('').length > SIZE_ROW_LENGTH) {
        return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, lineClamp: 1, children: t('product.multi.sizes') }));
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.MEDIUM, children: filteredSizes.map(size => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, children: getSizeDescription(size) }, size.sku))) }));
};
exports.ProductTileSizesInfo = ProductTileSizesInfo;
