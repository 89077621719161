"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterLink = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
const FilterLink = ({ items, toggleFilterValue }) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ButtonList, { stateLabel: true, size: cotopaxi_1.ButtonListItemSize.MEDIUM, buttons: items.map((item) => ({
        key: item.filterKey,
        noBubble: true,
        text: ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: item.active, children: (0, common_utils_1.capitalize)(item.name) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.SUBTLE, children: ["(", item.itemCount, ")"] })] })),
        onClick: (e) => toggleFilterValue(e, item),
    })) }));
exports.FilterLink = FilterLink;
