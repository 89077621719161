"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturnItems = exports.ReturnItemsSteps = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const React = tslib_1.__importStar(require("react"));
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const ReturnItemsList_1 = require("./ReturnItemsList");
const ReturnItemsMethodForm_1 = require("./ReturnItemsMethodForm");
const VerticalStep_1 = require("./../vertical-step/VerticalStep");
const common_types_1 = require("@as-react/common-types");
const react_redux_1 = require("react-redux");
const ReturnItemsEmptyState_1 = require("./ReturnItemsEmptyState");
const OrdersSelectors_1 = require("../../redux/orders/selectors/OrdersSelectors");
const RouterSelector_1 = require("../../redux/router/selectors/RouterSelector");
const OrdersActions_creators_1 = require("../../redux/orders/actions/OrdersActions.creators");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const Yup = tslib_1.__importStar(require("yup"));
const ScrollUtil_1 = require("../../util/ScrollUtil");
const DOMElementsConstants_1 = require("../../constants/DOMElementsConstants");
const AccountConstant_1 = require("../../constants/AccountConstant");
const NewAnnouncedReturn_1 = require("./NewAnnouncedReturn");
const UsedReturnOption_1 = require("./UsedReturnOption");
const HtmlInclude_1 = require("../common/html-include/HtmlInclude");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const ReturnItemsChangeHandler_1 = require("./ReturnItemsChangeHandler");
var ReturnItemsSteps;
(function (ReturnItemsSteps) {
    ReturnItemsSteps[ReturnItemsSteps["ITEMS"] = 0] = "ITEMS";
    ReturnItemsSteps[ReturnItemsSteps["METHOD"] = 1] = "METHOD";
})(ReturnItemsSteps || (exports.ReturnItemsSteps = ReturnItemsSteps = {}));
const validationSchemasReturnItems = (t) => Yup.object({
    saleLines: Yup.array().of(Yup.object().shape({
        returnReasonId: Yup.string().when('isSelected', {
            is: true,
            then: schema => schema.required(t('account:returnItems.form.reasonForReturn.error')),
        }),
        returnReasonFreeText: Yup.string().max(common_types_1.DefaultSettings.MAX_LENGTH_TEXT_AREA, ' '),
    })),
});
const validationSchemasMethods = Yup.object({
    countryId: Yup.string().required(),
    returnOptionId: Yup.string().required(),
});
const ReturnItems = ({ configuredReturnOptions }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    const dispatch = (0, react_redux_1.useDispatch)();
    const returnItems = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetReturnItems)());
    const returnReasons = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetReturnReasons)());
    const [activeStep, setActiveStep] = (0, react_1.useState)(ReturnItemsSteps.ITEMS);
    const sessionState = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetSessionState)());
    const locationHashValue = (0, react_redux_1.useSelector)((0, RouterSelector_1.makeGetLocationHash)());
    const submitState = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetSubmitReturnItemsState)());
    const announcedReturn = (0, react_redux_1.useSelector)((0, OrdersSelectors_1.makeGetAnnouncedReturn)());
    const prevSubmitState = (0, cotopaxi_1.usePrevious)(submitState);
    const [success, setSuccess] = (0, react_1.useState)(false);
    const hasSentPageLoadRule = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(() => {
        var _a;
        if (((_a = sessionState.authorities) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return;
        }
        dispatch(OrdersActions_creators_1.ordersActions.getReturnItems());
        dispatch(OrdersActions_creators_1.ordersActions.getReturnReasons());
    }, [sessionState.authorities]);
    (0, react_1.useEffect)(() => {
        if (locationHashValue.includes(AccountConstant_1.RETURN_ITEM_REDIRECT_PREFIX) && returnItems && (returnItems === null || returnItems === void 0 ? void 0 : returnItems.length) > 0) {
            ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(`.${locationHashValue.substr(1)}`));
        }
        if (returnItems !== null && !hasSentPageLoadRule.current) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnPortalReturnOverviewOpened((returnItems === null || returnItems === void 0 ? void 0 : returnItems.length) > 0));
            hasSentPageLoadRule.current = true;
        }
    }, [returnItems]);
    (0, react_1.useEffect)(() => {
        if (submitState === common_types_1.FetchStatus.RESOLVED && prevSubmitState === common_types_1.FetchStatus.PENDING) {
            setSuccess(true);
            ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(DOMElementsConstants_1.RETURN_ITEMS_COMPONENT));
        }
    }, [submitState, prevSubmitState]);
    (0, react_1.useEffect)(() => {
        if (activeStep === ReturnItemsSteps.METHOD) {
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnMethodOverviewOpened());
        }
    }, [activeStep]);
    const returnItemsValidationSchema = {
        [ReturnItemsSteps.ITEMS]: validationSchemasReturnItems(t),
        [ReturnItemsSteps.METHOD]: validationSchemasReturnItems(t).concat(validationSchemasMethods),
    };
    const initialFormValues = React.useMemo(() => {
        if (!returnItems) {
            return null;
        }
        const saleLines = returnItems.reduce((acc, returnItem) => {
            const returnItemSalesLines = returnItem.saleLines.map(saleLine => ({
                saleId: returnItem.saleId,
                saleLineId: saleLine.saleLineId,
                isSelected: false,
                qty: 1,
                originalPaymentMethod: returnItem.originalPaymentMethod,
                returnReasonId: undefined,
                customer: returnItem.customerResource,
            }));
            return [...acc, ...returnItemSalesLines];
        }, []);
        return {
            returnOptionId: '',
            saleLines,
        };
    }, [returnItems]);
    const getStepStatus = (step) => {
        if (step < activeStep) {
            return VerticalStep_1.VerticalStepStatus.PASSED;
        }
        if (step === activeStep) {
            return VerticalStep_1.VerticalStepStatus.CURRENT;
        }
        return undefined;
    };
    const handleStepClick = (step) => {
        if (step >= activeStep) {
            return undefined;
        }
        return () => {
            setActiveStep(step);
        };
    };
    const isMultipleSales = (array) => {
        var _a, _b;
        if (array && array.length > 1) {
            const loopLength = array.length;
            const firstValue = (_a = array === null || array === void 0 ? void 0 : array[0]) === null || _a === void 0 ? void 0 : _a.saleId;
            for (let i = 1; i < loopLength; i++) {
                if (((_b = array === null || array === void 0 ? void 0 : array[i]) === null || _b === void 0 ? void 0 : _b.saleId) !== firstValue) {
                    return true;
                }
            }
        }
        return false;
    };
    if (!returnItems) {
        return null;
    }
    if (returnItems.length === 0) {
        return (0, jsx_runtime_1.jsx)(ReturnItemsEmptyState_1.ReturnItemsEmptyState, { message: t('returnItems.emptyState.message') });
    }
    if (success && !!announcedReturn) {
        const lines = announcedReturn.announcedReturns.reduce((acc, announcedReturnObj) => {
            const findSale = returnItems.find(val => val.saleId === announcedReturnObj.saleId);
            if (findSale) {
                const findSaleLine = findSale.saleLines.find(val => val.saleLineId === announcedReturnObj.saleLineId);
                if (findSaleLine) {
                    const returnReason = returnReasons.find(reason => reason.returnReasonId === announcedReturnObj.returnReasonId);
                    return [
                        ...acc,
                        Object.assign(Object.assign({}, findSaleLine), { quantity: announcedReturnObj.qty, typeId: common_types_1.ReturnedSaleLineStatusTypes.ANNOUNCED, returnReasonDescription: returnReason === null || returnReason === void 0 ? void 0 : returnReason.description }),
                    ];
                }
            }
            return acc;
        }, []);
        const newAnnouncedReturns = {
            lines: lines,
            returnFromMultipleSales: isMultipleSales(announcedReturn.announcedReturns),
            statusMessage: common_types_1.ReturnedSaleStatusTypes.ANNOUNCED,
        };
        const statusMessage = ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { spacing: cotopaxi_1.FeedbackMessageSpacing.SMALL, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginLeft: 1, marginRight: 1 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, size: cotopaxi_1.TextSize.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { color: cotopaxi_1.TextColor.INHERIT, size: cotopaxi_1.TextSize.INHERIT, emphasized: true, inline: true, children: t('returnItems.success.status.label') }), t('returnItems.success.status.toBeReceived')] }) }), variant: cotopaxi_1.FeedbackMessageVariant.INFO }));
        const usedReturnOption = configuredReturnOptions
            ? Object.assign(Object.assign({}, (configuredReturnOptions.find(item => item.courier === announcedReturn.carrierName) ||
                {})), { costPrice: announcedReturn.shippingCost, refundInformationMessageType: announcedReturn.originalPaymentMethod
                    ? announcedReturn.originalPaymentMethod
                    : common_types_1.RefundInformationMessageType.OTHER, hasMultipleInvoices: newAnnouncedReturns.returnFromMultipleSales }) : null;
        return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.TITLE, children: t('returnItems.success.heading') }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: (usedReturnOption === null || usedReturnOption === void 0 ? void 0 : usedReturnOption.successMessage) ? ((0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: usedReturnOption.successMessage })) : (t('returnItems.success.content')) }) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 2 } }, mobileBleed: true, tabletBleed: true, children: statusMessage }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 2, marginBottom: 4 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { type: cotopaxi_1.HeadingType.SUBTITLE, children: [t('returnItems.confirmation.summary.heading'), " ", announcedReturn.announcementReference] }) }), !!newAnnouncedReturns && (0, jsx_runtime_1.jsx)(NewAnnouncedReturn_1.NewAnnouncedReturn, { returnedSale: newAnnouncedReturns }), !!usedReturnOption && ((0, jsx_runtime_1.jsx)(UsedReturnOption_1.UsedReturnOption, Object.assign({}, usedReturnOption, { announcementReference: announcedReturn.announcementReference })))] }));
    }
    const handleSubmit = (values) => {
        if (activeStep === ReturnItemsSteps.ITEMS) {
            setActiveStep(ReturnItemsSteps.METHOD);
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnProductsContinueClicked());
            ScrollUtil_1.ScrollUtil.scrollToElement(document.querySelector(DOMElementsConstants_1.RETURN_ITEMS_COMPONENT));
        }
        else if (activeStep === ReturnItemsSteps.METHOD) {
            const saleLineIds = values.saleLines
                .filter(saleLine => saleLine.isSelected)
                .map(saleLine => ({
                qty: saleLine.qty,
                saleId: saleLine.saleId,
                saleLineId: saleLine.saleLineId,
                returnReasonFreeText: saleLine.returnReasonFreeText,
                returnReasonId: saleLine.returnReasonId,
                originalPaymentMethod: saleLine.originalPaymentMethod,
            }));
            dispatch(AnalyticsActions_creators_1.analyticsActions.returnMethodConfirmClickClicked(values.returnOptionId || ''));
            dispatch(OrdersActions_creators_1.ordersActions.submitReturnItems({
                saleLineIds,
                address: values.address,
                returnCountryId: values.countryId,
                returnOptionId: values.returnOptionId ? parseInt(values.returnOptionId, 10) : 0,
            }));
        }
    };
    return (initialFormValues && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FormBuilder, { onSubmit: handleSubmit, validationSchema: returnItemsValidationSchema[activeStep], initialValues: initialFormValues, validateOnBlur: true, validateOnChange: false, name: "form-return-items", children: ({ values }) => {
            var _a;
            const selectedSaleLines = (_a = values === null || values === void 0 ? void 0 : values.saleLines) === null || _a === void 0 ? void 0 : _a.filter(saleLine => saleLine.isSelected);
            const returnHasMultipleSales = isMultipleSales(selectedSaleLines);
            return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { mobileBleed: true }), (0, jsx_runtime_1.jsxs)(VerticalStep_1.VerticalStep, { index: ReturnItemsSteps.ITEMS, title: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { align: cotopaxi_1.GroupAlign.SPACE_BETWEEN, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)("div", { onClick: handleStepClick(ReturnItemsSteps.ITEMS), role: "button", tabIndex: 0, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: t('returnItems.step1.heading') }) }), getStepStatus(ReturnItemsSteps.ITEMS) === VerticalStep_1.VerticalStepStatus.PASSED && ((0, jsx_runtime_1.jsx)(cotopaxi_1.MediaQueryWrapper, { query: [cotopaxi_1.MediaQuery.TABLET_SMALL, cotopaxi_1.MediaQuery.TABLET, cotopaxi_1.MediaQuery.DESKTOP], children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [t('returnItems.tab.returnItems.selectedCount', {
                                                count: selectedSaleLines.length,
                                            }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, text: t('returnItems.tab.returnItems.selectedCount.action'), onClick: handleStepClick(ReturnItemsSteps.ITEMS) })] }) }))] }), status: getStepStatus(ReturnItemsSteps.ITEMS), children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3, marginBottom: 3 } }, children: (0, jsx_runtime_1.jsx)(ReturnItemsList_1.ReturnItemsList, { returnItems: returnItems, returnReasons: returnReasons }) })] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { mobileBleed: true }), (0, jsx_runtime_1.jsxs)(VerticalStep_1.VerticalStep, { index: ReturnItemsSteps.METHOD, title: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, children: t('returnItems.step2.heading') }), status: getStepStatus(ReturnItemsSteps.METHOD), children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, {}), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 3 } }, children: (0, jsx_runtime_1.jsx)(ReturnItemsMethodForm_1.ReturnItemsMethodForm, { configuredReturnOptions: configuredReturnOptions, returnHasMultipleSales: returnHasMultipleSales, saleLines: selectedSaleLines }) })] }), getStepStatus(ReturnItemsSteps.METHOD) !== VerticalStep_1.VerticalStepStatus.CURRENT && (0, jsx_runtime_1.jsx)(cotopaxi_1.Divider, { mobileBleed: true }), (0, jsx_runtime_1.jsx)(ReturnItemsChangeHandler_1.ReturnItemsChangeHandler, {})] }));
        } })));
};
exports.ReturnItems = ReturnItems;
