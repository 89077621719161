"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTileExtensiveBlock = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductTileExtensiveBlock = ({ url, colourId, colours, deliveryPromise, deliveryPromiseExplanationElement, oneSizeProduct, buttonState, addToBasket, variant, clickAndCollectOnly, inStoreOnly, outOfStock, colorOutOfStock, inProductTile, hideAddToBasketBtn, sizeSelect, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isTablet = !isMobile && !isDesktop;
    const isSimple = variant === 'simple';
    const isSimpleAndMobile = isMobile && isSimple;
    const isSimpleAndTablet = isTablet && isSimple;
    const [color, sizes] = (0, react_1.useMemo)(() => {
        const tmpColor = colours.find(item => item.colourId === colourId);
        const tmpSizes = tmpColor ? tmpColor.sizes : [];
        return [tmpColor, tmpSizes];
    }, [colourId, colours]);
    const oneColourSize = sizes.length === 1;
    const icon = {
        name: cotopaxi_1.IconName.CART,
        size: cotopaxi_1.IconSize.MEDIUM,
    };
    const buttonText = () => {
        if (isSimpleAndMobile) {
            return undefined;
        }
        else if (isSimpleAndTablet) {
            return t('pdp.cart.addtobasket.btn');
        }
        return !oneColourSize || oneSizeProduct ? t('pdp.cart.addtobasket.btn') : undefined;
    };
    const colourLabel = colours.length === 1
        ? t('product.extensiveBlock.singleColour')
        : t('product.extensiveBlock.multipleColours', { count: colours.length });
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.NONE, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: inProductTile ? 1 : 0, marginTop: inProductTile ? 1 : 0 } }, children: !isSimple && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.SUBTLE, lineClamp: 1, children: outOfStock || clickAndCollectOnly || inStoreOnly ? colourLabel : color.description }), !outOfStock &&
                            !clickAndCollectOnly &&
                            !inStoreOnly &&
                            (colours.length > 1 && url ? ((0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: url, size: cotopaxi_1.LinkSize.SMALL, children: colourLabel })) : ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, size: cotopaxi_1.TextSize.SMALL, children: "\u00A0" })))] })) }), !outOfStock && !clickAndCollectOnly && !inStoreOnly && !colorOutOfStock && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { gutter: oneSizeProduct ? cotopaxi_1.GroupGutter.NONE : cotopaxi_1.GroupGutter.SMALL, inline: !oneSizeProduct, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [!oneSizeProduct && !isSimpleAndMobile && !isSimpleAndTablet && Boolean(sizeSelect) && sizeSelect, !hideAddToBasketBtn && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: oneSizeProduct || isSimpleAndMobile || isSimpleAndTablet, type: cotopaxi_1.ButtonType.BUTTON, size: cotopaxi_1.ButtonSize.NORMAL, variant: cotopaxi_1.ButtonVariant.BRANDED_ALT, state: buttonState, text: buttonText(), finisher: { icon: cotopaxi_1.IconName.CHECK }, icon: isSimpleAndMobile || (!oneSizeProduct && !isSimpleAndMobile && !isSimpleAndTablet) ? icon : undefined, iconLeft: (oneSizeProduct || isSimpleAndTablet) && !isSimpleAndMobile ? icon : undefined, onClick: addToBasket }))] })), !!deliveryPromise &&
                !isSimpleAndMobile &&
                !isSimpleAndTablet &&
                !outOfStock &&
                !clickAndCollectOnly &&
                !inStoreOnly &&
                !colorOutOfStock && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: [typeof deliveryPromise === 'string' ? ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, color: cotopaxi_1.TextColor.POSITIVE, inline: true, dataQA: "delivery-promise-text", children: [deliveryPromise, ' '] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: deliveryPromise })), !!deliveryPromiseExplanationElement && (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [" ", deliveryPromiseExplanationElement] })] })), (clickAndCollectOnly || inStoreOnly) && !outOfStock && ((0, jsx_runtime_1.jsx)(cotopaxi_1.FeedbackMessage, { variant: cotopaxi_1.FeedbackMessageVariant.INFO, text: hideAddToBasketBtn
                    ? t('product.store.source.excluded')
                    : clickAndCollectOnly
                        ? t('product.clickAndCollectOnly')
                        : t('product.inStoreOnly') }))] }));
};
exports.ProductTileExtensiveBlock = ProductTileExtensiveBlock;
