"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPlacementCopyBox = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const common_utils_1 = require("@as-react/common-utils");
const cotopaxi_1 = require("@as/cotopaxi");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const useProductPlacementCopyBox_1 = require("./useProductPlacementCopyBox");
const ProductPlacementCopyBox = ({ ppc }) => {
    const { content, renderedContainerElement, PPCPositionTypes, isExternal } = (0, useProductPlacementCopyBox_1.useProductPlacementCopyBox)(ppc);
    if ((0, common_utils_1.isNullOrUndefined)(content) || content.length === 0)
        return null;
    const getSpacings = () => {
        if (isExternal()) {
            if (ppc.placementPositionId === common_types_1.PPCPositionTypesUK.DIGITAL_PARTNER_MESSAGING) {
                return {
                    mobile: {
                        spacing: {
                            marginBottom: 2,
                        },
                    },
                };
            }
            return {};
        }
        return {
            mobile: {
                spacing: {
                    marginTop: ppc.placementPositionId === PPCPositionTypes.ABOVE_BUY_BUTTON
                        ? 1
                        : ppc.placementPositionId === PPCPositionTypes.BELOW_BUY_BUTTON
                            ? 2
                            : 0,
                    marginBottom: ppc.placementPositionId === PPCPositionTypes.ABOVE_DESCRIPTION ? 3 : 2,
                    paddingTop: 1,
                    paddingRight: 2,
                    paddingBottom: 1,
                    paddingLeft: 2,
                },
                theme: cotopaxi_1.BoxTheme.NOTICE,
            },
            tablet: {
                spacing: {
                    paddingRight: 1,
                    paddingLeft: 1,
                },
            },
        };
    };
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, Object.assign({ mobileBleed: !isExternal() }, getSpacings(), { children: (0, jsx_runtime_1.jsx)("div", { ref: renderedContainerElement, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: content }) }) })));
};
exports.ProductPlacementCopyBox = ProductPlacementCopyBox;
