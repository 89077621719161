"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreviewWithValidationError = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_icons_1 = require("@yonderland/andes-icons");
const PreviewWithValidationError = ({ isValid, children }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "1", children: [children, !isValid && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "1", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "1", children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.WarningCircleIcon, color: "error", size: "xs" }) }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "error", children: t('checkout.delivery.validation.error') })] }))] }));
};
exports.PreviewWithValidationError = PreviewWithValidationError;
