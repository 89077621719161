"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCollageItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_types_1 = require("@as-react/common-types");
const LabelsOverlayAlpha_1 = require("../../common/labels-overlay/LabelsOverlayAlpha");
const ProductCollageWishListButton_1 = require("./ProductCollageWishListButton");
const Video_1 = require("../../video/Video");
const VideoUtil_1 = require("../../../util/VideoUtil");
const ProductConstants_1 = require("../../../constants/ProductConstants");
const ProductCollageItem = ({ item, index, showLabels, showWishlistButton, wishListProduct, alt, showGenderIcon, genderFeatureId, savingsPercentage, priceOverlay, showNewProductLabel, showOnlineExclusiveLabel, showSustainabilityLabel, onClick, }) => {
    var _a;
    const handleClick = (e) => {
        onClick === null || onClick === void 0 ? void 0 : onClick(e, index);
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { dataTestId: "product_collage_item", columns: "stack", gap: 0, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "quiet-3", aspectRatio: "2/3", contentAlign: "center", children: item.video && ((_a = item.video.productVideoDetail) === null || _a === void 0 ? void 0 : _a.url) ? ((0, jsx_runtime_1.jsx)(Video_1.Video, { url: item.video.productVideoDetail.url, playerType: (0, VideoUtil_1.getVideoPlayerType)(item.video.productVideoDetail.url) })) : ((0, jsx_runtime_1.jsx)(andes_react_1.ImageMagnify, { config: {
                        smallImage: {
                            src: item.sourceSet[common_types_1.ProductImageSizesPortraitEnum.detail],
                            width: ProductConstants_1.ProductImageWidth.DETAIL,
                            height: ProductConstants_1.ProductImageHeight.DETAIL,
                            isFluidWidth: true,
                        },
                        largeImage: {
                            src: item.sourceSet[common_types_1.ProductImageSizesPortraitEnum.zoom],
                            width: ProductConstants_1.ProductImageWidth.ZOOM,
                            height: ProductConstants_1.ProductImageHeight.ZOOM,
                        },
                    }, alt: alt, onClick: handleClick })) }), showLabels && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { display: "flex", justify: "start", align: "end", children: (0, jsx_runtime_1.jsx)(LabelsOverlayAlpha_1.LabelsOverlayAlpha, { showGenderIcon: showGenderIcon, genderFeatureId: genderFeatureId, savingsPercentage: savingsPercentage, priceOverlay: priceOverlay, showNewProductLabel: showNewProductLabel, showOnlineExclusiveLabel: showOnlineExclusiveLabel, showSustainabilityLabel: showSustainabilityLabel, dataTestId: "product_collage_image_overlay" }) })), showWishlistButton && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { display: "flex", justify: "end", align: "start", children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "5", paddingInlineEnd: "5", children: (0, jsx_runtime_1.jsx)(ProductCollageWishListButton_1.ProductCollageWishListButton, { wishListProduct: wishListProduct }) }) }))] }));
};
exports.ProductCollageItem = ProductCollageItem;
