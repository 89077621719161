"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortControl = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const SortControlPopover_1 = require("./SortControlPopover");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SortControl = props => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { orderItems, currentOrderItem, onOrderChange } = props;
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    const onItemClick = (newOrder) => {
        handlePopoverClose();
        return onOrderChange(newOrder);
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { flex: { shrink: 0 }, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, children: [t('product:product.sort'), ": "] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { iconRight: {
                    name: anchorEl ? cotopaxi_1.IconName.CHEVRON_UP : cotopaxi_1.IconName.CHEVRON_DOWN,
                    color: cotopaxi_1.IconColor.ACTION,
                    size: cotopaxi_1.IconSize.MEDIUM,
                }, onClick: handlePopoverOpen, variant: cotopaxi_1.ButtonVariant.TRANSPARENT, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, inline: true, children: t(`product:${currentOrderItem.value}`) }), size: cotopaxi_1.ButtonSize.SMALL }), (0, jsx_runtime_1.jsx)(SortControlPopover_1.SortControlPopover, { orderItems: orderItems, currentOrderItem: currentOrderItem, anchorEl: anchorEl, onClose: handlePopoverClose, onOrderChange: onItemClick })] }));
};
exports.SortControl = SortControl;
