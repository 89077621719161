"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const AdvantagesSectionV2_1 = require("../../advantages-section/AdvantagesSectionV2");
const cotopaxi_1 = require("@as/cotopaxi");
const AdvantagesBlock = ({ advantages }) => {
    if (!(advantages === null || advantages === void 0 ? void 0 : advantages.length))
        return null;
    return (0, jsx_runtime_1.jsx)(AdvantagesSectionV2_1.AdvantagesSection, { items: advantages, textSize: cotopaxi_1.TextSize.MEDIUM });
};
exports.default = AdvantagesBlock;
