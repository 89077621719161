"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalProductSelectorAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const ImageUtil_1 = require("../../../util/ImageUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const common_utils_1 = require("@as-react/common-utils");
const react_i18next_1 = require("react-i18next");
const ProductDetailV2Container_1 = require("../../../containers/product/ProductDetailV2Container");
const react_redux_1 = require("react-redux");
const AnalyticsActions_creators_1 = require("../../../redux/analytics/actions/AnalyticsActions.creators");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_icons_1 = require("@yonderland/andes-icons");
const ProductConstants_1 = require("../../../constants/ProductConstants");
const AdditionalProductSelectorAlpha = ({ additionalProductSelector }) => {
    const { currency, productImageConfig } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { selectedAdditionalProducts, setSelectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const { additionalProducts } = additionalProductSelector;
    (0, react_1.useEffect)(() => {
        (additionalProducts === null || additionalProducts === void 0 ? void 0 : additionalProducts.length) > 0 && dispatch(AnalyticsActions_creators_1.analyticsActions.additionalProductSelector(additionalProducts));
    }, [additionalProducts]);
    if ((additionalProducts === null || additionalProducts === void 0 ? void 0 : additionalProducts.length) <= 0) {
        return null;
    }
    const handleSelectionChange = (_event, product) => {
        setSelectedAdditionalProducts(product);
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [additionalProductSelector.title && (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: additionalProductSelector.title }), additionalProducts && ((0, jsx_runtime_1.jsx)(andes_react_1.ResourceList, { dataTestId: "additional_product_selector", items: additionalProducts, hasCheckbox: true, selectable: true, selectedItems: selectedAdditionalProducts, onSelectionChange: handleSelectionChange, renderItem: (additionalProduct, index) => {
                    if (!additionalProduct.product) {
                        return null;
                    }
                    const { product, displayedColor } = additionalProduct;
                    const { productCode, productTranslatedProperties } = product;
                    const prices = (0, common_utils_1.generateCotopaxiPriceViewModel)(displayedColor, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency);
                    return ((0, jsx_runtime_1.jsxs)(andes_react_1.ProductTileHorizontal, { dataTestId: "additional_product_tile", gap: "2", product: Object.assign(Object.assign(Object.assign({}, product), prices), { currency, image: (0, ImageUtil_1.getImageSourceSetByProductCode)(productCode, displayedColor.image, productImageConfig)[common_types_1.ProductImageSizesPortraitEnum.thumb] }), children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "media", children: (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Image, { width: ProductConstants_1.ProductImageWidth.ADDITIONAL_TILE, height: ProductConstants_1.ProductImageHeight.ADDITIONAL_TILE }) }), (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "content", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "1", contentAlign: "between", wrap: "nowrap", children: [productTranslatedProperties.brand && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, lineClamp: 1, children: productTranslatedProperties.brand })), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.ProductTilePrice, {}) })] }), (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "1", wrap: "nowrap", children: [productTranslatedProperties.title && ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subtle", lineClamp: 2, children: productTranslatedProperties.title })), additionalProduct.tooltip && ((0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { id: `aps-tooltip-${index}`, hasCloseBtn: false, text: additionalProduct.tooltip, children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { color: "subtle", source: andes_icons_1.InfoIcon, size: "sm" }) }))] })] }) })] }));
                } }))] }));
};
exports.AdditionalProductSelectorAlpha = AdditionalProductSelectorAlpha;
