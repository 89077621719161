"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Map = exports.OpenStreetIcons = exports.SEARCH_LOCATION_ID = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_utils_1 = require("@as-react/common-utils");
// "react-leaflet" does not work with SSR, we must use dynamic imports
const loadLeaflet = (leaflet) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    const reactLeaflet = yield Promise.resolve().then(() => tslib_1.__importStar(require('react-leaflet')));
    leaflet(reactLeaflet);
});
const loadLeafletIcon = (leafletIcon) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    const reactLeaflet = yield Promise.resolve().then(() => tslib_1.__importStar(require('leaflet')));
    leafletIcon(reactLeaflet);
});
const loadReactDOMServer = (DOMServer) => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    const reactDOMServer = yield Promise.resolve().then(() => tslib_1.__importStar(require('react-dom/server')));
    DOMServer(reactDOMServer);
});
const getFasciaIconUrl = (url, mainWebShop, iconName) => url.replace('{mainWebShop}', mainWebShop).replace('{iconName}', iconName);
const ZOOM = 6;
exports.SEARCH_LOCATION_ID = 'SEARCH_LOCATION_ID';
var OpenStreetIcons;
(function (OpenStreetIcons) {
    OpenStreetIcons["IN_STOCK"] = "instock";
    OpenStreetIcons["OUT_STOCK"] = "outstock";
    OpenStreetIcons["DEFAULT"] = "default";
})(OpenStreetIcons || (exports.OpenStreetIcons = OpenStreetIcons = {}));
const defaultIconConfig = {
    fasciaIcon: {
        iconAnchor: [30, 45],
        iconUrl: '/content/dam/{mainWebShop}/open-street-map-icons/{iconName}.png',
    },
    baseIcon: { iconSize: [30, 30], iconAnchor: [15, 30] },
};
const Map = ({ active, containerStyles = null, isOpen, markers, focusOnMarkers, mapCenter, defaultBounds = null, showFasciaIcons, showStock, activePointId, keyboard = false, onMarkerClick, noMarkersAnimation, markerIconConfigOverrides, }) => {
    const [leaflet, setLeaflet] = (0, react_1.useState)(null);
    const [leafletIcon, setLeafletIcon] = (0, react_1.useState)(null);
    const [reactDOMServe, setReactDOMServe] = (0, react_1.useState)(null);
    const [activeMarkerId, setActiveMarkerId] = (0, react_1.useState)(null);
    const [zoom, setZoom] = (0, react_1.useState)(ZOOM);
    const [boundsFeatureGroup, setBoundsFeatureGroup] = (0, react_1.useState)(defaultBounds);
    const [mapInitialized, setIsMapInitialized] = (0, react_1.useState)(false);
    const mapRefElement = (0, react_1.useRef)(null);
    const markersRefElements = (0, react_1.useRef)({});
    const markerIconConfig = (0, common_utils_1.mergeObjectDeep)(defaultIconConfig, markerIconConfigOverrides || {});
    (0, react_1.useEffect)(() => {
        loadLeaflet(setLeaflet);
        loadLeafletIcon(setLeafletIcon);
        loadReactDOMServer(setReactDOMServe);
    }, []);
    (0, react_1.useEffect)(() => {
        var _a;
        if (active && ((_a = mapRefElement.current) === null || _a === void 0 ? void 0 : _a.leafletElement) && boundsFeatureGroup) {
            mapRefElement.current.leafletElement.fitBounds(boundsFeatureGroup);
        }
    }, [active, boundsFeatureGroup]);
    (0, react_1.useEffect)(() => {
        !isOpen && setActiveMarkerId(null);
        if (mapRefElement.current) {
            mapRefElement.current.leafletElement.invalidateSize();
        }
    }, [isOpen]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (!activePointId) {
            setActiveMarkerId(null);
        }
        else if (activePointId && activePointId !== activeMarkerId) {
            const activeMarker = markers.find(marker => marker.id === activePointId);
            const latitude = activeMarker.latitude;
            const longitude = activeMarker.longitude;
            (_a = mapRefElement.current) === null || _a === void 0 ? void 0 : _a.leafletElement.flyTo([latitude, longitude]);
            setActiveMarkerId(activePointId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePointId, markers]);
    const groupRef = (0, react_1.useCallback)((node) => {
        var _a, _b, _c, _d;
        if (node && markers.length && !(focusOnMarkers === null || focusOnMarkers === void 0 ? void 0 : focusOnMarkers.length)) {
            setBoundsFeatureGroup(node.leafletElement.getBounds());
        }
        else if (focusOnMarkers === null || focusOnMarkers === void 0 ? void 0 : focusOnMarkers.length) {
            const bounds = focusOnMarkers.map(marker => [marker.latitude, marker.longitude]);
            (_b = (_a = mapRefElement.current) === null || _a === void 0 ? void 0 : _a.leafletElement) === null || _b === void 0 ? void 0 : _b.closePopup();
            setBoundsFeatureGroup(bounds);
            // open popup for the nearest focus marker
            if (focusOnMarkers[0].openPopup && mapInitialized) {
                (_d = (_c = markersRefElements.current[focusOnMarkers[0].id]) === null || _c === void 0 ? void 0 : _c.leafletElement) === null || _d === void 0 ? void 0 : _d.openPopup();
            }
        }
        else if (!markers.length && defaultBounds) {
            setBoundsFeatureGroup(defaultBounds);
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [markers, defaultBounds, focusOnMarkers, mapInitialized]);
    if (!leaflet || !leafletIcon || !reactDOMServe) {
        return null;
    }
    const { Map: MapContainer, TileLayer, Marker, FeatureGroup: FeatureGroupContainer, Popup } = leaflet;
    const getIcon = (available, activeItem, isSearchLocation, storeMainWebShop) => {
        var _a, _b, _c, _d, _e;
        return showFasciaIcons && !isSearchLocation
            ? leafletIcon.icon({
                className: activeItem && !noMarkersAnimation ? 'as-a-image--animation-bop-cpu' : '',
                iconAnchor: (_a = markerIconConfig.fasciaIcon) === null || _a === void 0 ? void 0 : _a.iconAnchor,
                iconSize: (_b = markerIconConfig.fasciaIcon) === null || _b === void 0 ? void 0 : _b.iconSize,
                iconUrl: getFasciaIconUrl((_c = markerIconConfig.fasciaIcon) === null || _c === void 0 ? void 0 : _c.iconUrl, storeMainWebShop, !showStock ? OpenStreetIcons.DEFAULT : available ? OpenStreetIcons.IN_STOCK : OpenStreetIcons.OUT_STOCK),
            })
            : leafletIcon.divIcon({
                className: '',
                iconAnchor: (_d = markerIconConfig === null || markerIconConfig === void 0 ? void 0 : markerIconConfig.baseIcon) === null || _d === void 0 ? void 0 : _d.iconAnchor,
                iconSize: (_e = markerIconConfig === null || markerIconConfig === void 0 ? void 0 : markerIconConfig.baseIcon) === null || _e === void 0 ? void 0 : _e.iconSize,
                html: reactDOMServe.renderToString((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.MAP_MARKER, size: cotopaxi_1.IconSize.EXTRA_MAJOR, animation: activeItem && !noMarkersAnimation ? cotopaxi_1.IconAnimation.BOP : null, color: !showStock ? cotopaxi_1.IconColor.DEFAULT : available ? cotopaxi_1.IconColor.POSITIVE : cotopaxi_1.IconColor.NOTICE })),
            });
    };
    const handleMarkerClick = (event, id, coordinates) => {
        event.originalEvent.stopImmediatePropagation();
        setActiveMarkerId(id);
        onMarkerClick === null || onMarkerClick === void 0 ? void 0 : onMarkerClick(id);
        mapRefElement.current.leafletElement.flyTo(coordinates);
    };
    const mapMarkersCallback = ({ id, longitude, latitude, available, storeMainWebShop, popupContent }) => ((0, jsx_runtime_1.jsx)(Marker, { ref: el => (markersRefElements.current[id] = el), icon: getIcon(available, id === activeMarkerId, id === exports.SEARCH_LOCATION_ID, storeMainWebShop), keyboard: false, onClick: (event) => handleMarkerClick(event, id, [latitude, longitude]), position: [latitude, longitude], children: popupContent && (0, jsx_runtime_1.jsx)(Popup, { autoPan: false, children: popupContent }) }, id));
    return ((0, jsx_runtime_1.jsxs)(MapContainer, { style: containerStyles, scrollWheelZoom: false, center: mapCenter, keyboard: keyboard, ref: mapRefElement, bounds: defaultBounds, whenReady: () => setIsMapInitialized(true), onZoomEnd: () => setZoom(mapRefElement.current.leafletElement.getZoom()), zoom: zoom, children: [(0, jsx_runtime_1.jsx)(TileLayer, { detectRetina: true, url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png", attribution: "\u00A9 <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors" }), (0, jsx_runtime_1.jsx)(FeatureGroupContainer, { ref: groupRef, children: markers.map(mapMarkersCallback) })] }));
};
exports.Map = Map;
