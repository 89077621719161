"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelectButtonsSplitted = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const cotopaxi_1 = require("@as/cotopaxi");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ProductConstants_1 = require("../../../constants/ProductConstants");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const SizeSelectButtonsSplitted = ({ type, id, selectedOption, setSelectedOption, lengthOptions, setLengthOptions, setSizeOptions, sizeOptions, isNotifyMeSizeError, options, filteredSizes, basketValidationState, selectedColorId, setFirstSelectedType, firstSelectedType, sizeCombinationNotExist, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const { sizesMap, lengthsMap } = (0, ProductUtil_1.splitSizesBySizeAndLength)(filteredSizes);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)();
    const isSize = type === 'size';
    const typeMap = isSize ? sizesMap : lengthsMap;
    const typeOptions = isSize ? sizeOptions : lengthOptions;
    const withNotifyMe = typeOptions.some(option => option.isNotifyMe);
    const chipSize = `${Math.max(...[...typeMap.keys()].map(s => s.length)) + (withNotifyMe ? 2 : 0)}ch + ${ProductConstants_1.PRODUCT_SIZE_CHIP_INNER_PADDING + (withNotifyMe ? 4 : 0)}px`;
    const getSizeButtonState = (option, selected) => {
        if (errorMessage &&
            !option.disabled &&
            (!option.isNotifyMe || (!option.isSoldOut && isNotifyMeSizeError)) &&
            !selectedOption) {
            return 'error';
        }
        if (option.label === selected) {
            return 'activated';
        }
        return 'enabled';
    };
    const handleButtonClick = (option) => () => {
        setErrorMessage(undefined);
        setSelectedOption(option.label.toString());
        if (!firstSelectedType) {
            setFirstSelectedType(type);
        }
        const updatedOptions = (0, ProductUtil_1.getActiveSizeOptions)(typeMap.get(option.label.toString()) || [], options, isSize ? lengthOptions : sizeOptions, isSize ? 'length' : 'size');
        if (isSize) {
            setLengthOptions(updatedOptions);
        }
        else {
            setSizeOptions(updatedOptions);
        }
    };
    (0, react_1.useEffect)(() => {
        setErrorMessage(undefined);
    }, [selectedColorId]);
    (0, react_1.useEffect)(() => {
        if ((basketValidationState === common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT ||
            basketValidationState === common_types_1.BasketButtonError.SKU ||
            isNotifyMeSizeError) &&
            !selectedOption) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(id), isDesktop, () => setErrorMessage((0, ProductUtil_1.getSizeButtonsError)(basketValidationState, type)));
        }
    }, [basketValidationState, selectedOption, isNotifyMeSizeError, sizeCombinationNotExist]);
    const buttonOptions = (0, react_1.useMemo)(() => typeOptions.map(option => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Chip, { state: getSizeButtonState(option, selectedOption), text: (0, jsx_runtime_1.jsxs)(cotopaxi_1.ConditionalWrapper, { condition: Boolean(type !== firstSelectedType && ((option.isSoldOut && option.isNotifyMe) || option.disabled)), wrapper: children => ((0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, strike: true, size: cotopaxi_1.TextSize.INHERIT, children: children })), children: [option.label, ' ', option.isNotifyMe && type !== firstSelectedType && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.EMAIL, color: "N300", size: cotopaxi_1.IconSize.MINOR, inline: true }))] }, option.label), onClick: handleButtonClick(option), noInlinePadding: true }, option.label))), [options, sizeOptions, lengthOptions, errorMessage]);
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { id: id, dataAttributes: { 'data-qa': id }, mobile: { spacing: { marginBottom: 3 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Label, { emphasized: true, children: t(`pdp.size.buttons.${type}`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1, marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Grid, { scalable: {
                        min: `calc(${chipSize})`,
                        maxCols: typeOptions.length <= 2 ? { mobile: 2, desktop: 3 } : { mobile: 4, desktop: 6 },
                        repeatPattern: typeOptions.length <= 2 ? 'auto-fill' : undefined,
                    }, gap: "s", dataQA: `${type}-select-buttons`, children: buttonOptions }) }), errorMessage && ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: cotopaxi_1.IconName.INFORMATION_OUTLINE, color: cotopaxi_1.IconColor.NEGATIVE }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, color: cotopaxi_1.TextColor.NEGATIVE, children: t(errorMessage) })] }))] }));
};
exports.SizeSelectButtonsSplitted = SizeSelectButtonsSplitted;
