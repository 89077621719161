"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelsOverlayAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_components_1 = require("@as-react/common-components");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const labelsOverlay_utils_1 = require("./labelsOverlay.utils");
const LABELS_GAP = '1';
const LabelsOverlayAlpha = ({ showGenderIcon, genderFeatureId, savingsPercentage, priceOverlay, showNewProductLabel, showOnlineExclusiveLabel, showSustainabilityLabel, dataTestId, maxLabels, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { mainWebShop, market, feature_toggles: { our_planet: showOurPlanet }, } = aemConfiguration;
    const labels = [];
    const labelsToShow = maxLabels ? maxLabels : market === 'nl' ? 2 : 3;
    const { overlayTagColor, overlayTagContent } = (0, labelsOverlay_utils_1.getProductPriceOverlayTagColorAndContent)(mainWebShop, priceOverlay);
    const showGenderLabel = showGenderIcon && !!genderFeatureId && !!(0, labelsOverlay_utils_1.getIcon)(genderFeatureId);
    if (showGenderLabel) {
        labels.push((0, jsx_runtime_1.jsx)(andes_react_1.Tag, { size: "lg", dataTestId: "gender_icon", variant: "light", icon: (0, labelsOverlay_utils_1.getIcon)(genderFeatureId) }, "gender_icon"));
    }
    if (savingsPercentage && savingsPercentage > 0) {
        labels.push((0, jsx_runtime_1.jsx)(andes_react_1.Tag, { size: "lg", variant: "sale", dataTestId: "savings_percentage", text: `-${savingsPercentage}%` }, "dynamic_discount_label"));
    }
    if (overlayTagContent) {
        labels.push((0, jsx_runtime_1.jsx)(andes_react_1.Tag, { size: "lg", variant: overlayTagColor, dataTestId: "price_overlay", text: overlayTagContent }, "commercial_label"));
    }
    if (showNewProductLabel) {
        const { tagColor, translationKey } = (0, labelsOverlay_utils_1.getNewProductOverlayTagColorAndTranslationKey)(mainWebShop);
        labels.push((0, jsx_runtime_1.jsx)(andes_react_1.Tag, { size: "lg", variant: tagColor, text: t(translationKey) }, "new_product_label"));
    }
    if (showOnlineExclusiveLabel) {
        labels.push((0, jsx_runtime_1.jsx)(andes_react_1.Tag, { size: "lg", dataTestId: "online_exclusive", text: t('pdp.overlay.onlineexclusive') }, "online_exclusive_label"));
    }
    if (showSustainabilityLabel && showOurPlanet) {
        labels.push((0, jsx_runtime_1.jsx)(andes_react_1.Tag, { size: "lg", variant: "sustainability", icon: andes_icons_1.LeafFillIcon, text: t('product.sustainable') }, "sustainability_label"));
    }
    if (labels.length === 0) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: LABELS_GAP, flex: { grow: 0 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { dataTestId: dataTestId, gap: LABELS_GAP, children: labels.slice(0, labelsToShow) }) }));
};
exports.LabelsOverlayAlpha = LabelsOverlayAlpha;
