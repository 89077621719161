"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSpecialtyMessageAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const ProductSpecialtyMessageAlpha = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const specialtyType = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSpecialtyType)());
    if (!specialtyType) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { dataTestId: "product_specialty_message", padding: "4", backgroundColor: "informative-bg", borderRadius: "md", children: (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { color: "informative", children: [t('product.specialty.message', {
                    specialty_type: t(`product.specialty.type.${specialtyType}`),
                }), ' ', (0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { text: t(`product.specialty.tooltip.${specialtyType}`), hasCloseBtn: false, children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { size: "xs", source: andes_icons_1.InfoIcon }) })] }) }));
};
exports.ProductSpecialtyMessageAlpha = ProductSpecialtyMessageAlpha;
