"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductQuantityAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const andes_react_1 = require("@yonderland/andes-react");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const PDP_QUANTITY_SELECTOR = 'pdp-quantity';
const ProductQuantityAlpha = ({ quantity, setQuantity }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: t('pdp.quantity') }), (0, jsx_runtime_1.jsx)(andes_react_1.QuantitySelector, { dataTestId: PDP_QUANTITY_SELECTOR, quantity: quantity, min: 1, onUpdate: setQuantity })] }));
};
exports.ProductQuantityAlpha = ProductQuantityAlpha;
