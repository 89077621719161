"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const cotopaxi_1 = require("@as/cotopaxi");
const SwatchUtil_1 = require("../../../util/SwatchUtil");
const react_i18next_1 = require("react-i18next");
const common_utils_1 = require("@as-react/common-utils");
const PRODUCT_TILE_SWATCH_DIMENSION = 24;
const ColorSwatches = ({ productCode, colors, selectedColorId, productCdnDomains, setProductColor, swatchInformation, isShopLanding, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [description, setDescription] = (0, react_1.useState)();
    const [previousProductCode, setPreviousProductCode] = (0, react_1.useState)();
    const [previousSelectedColorId, setPreviousSelectedColorId] = (0, react_1.useState)();
    const [swatches, setSwatches] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        const selectedColorVariation = colors.find((colorVariation) => colorVariation.colorId === selectedColorId);
        if (productCode !== previousProductCode || !(swatches === null || swatches === void 0 ? void 0 : swatches.length)) {
            setDescription(selectedColorVariation.translations.description);
            setPreviousProductCode(productCode);
            setPreviousSelectedColorId(selectedColorId);
            setSwatches(colors.map((productColor) => (0, SwatchUtil_1.generateSwatchFromProductColorVariation)(productColor, selectedColorId, productCode, productCdnDomains)));
        }
        else if (selectedColorId !== previousSelectedColorId) {
            setDescription(selectedColorVariation.translations.description);
            setPreviousSelectedColorId(selectedColorId);
            setSwatches(swatches.map((swatch) => {
                if (!swatch.selected && swatch.colourId === selectedColorId) {
                    return Object.assign(Object.assign({}, swatch), { selected: true });
                }
                else if (swatch.selected && swatch.colourId !== selectedColorId) {
                    return Object.assign(Object.assign({}, swatch), { selected: false });
                }
                else {
                    return swatch;
                }
            }));
        }
        else if (swatches && swatches.length) {
            const newSwatchesState = swatches.map((swatch) => {
                const updatedColor = colors.find((newColor) => newColor.colorId === swatch.colourId);
                if (!(0, common_utils_1.isNullOrUndefined)(updatedColor)) {
                    return Object.assign(Object.assign({}, swatch), { hasDiscount: updatedColor.hasDiscount });
                }
                return swatch;
            });
            JSON.stringify(newSwatchesState) !== JSON.stringify(swatches) && setSwatches(newSwatchesState);
        }
    }, [swatches, selectedColorId]);
    const setCurrentProductColor = (colourId) => {
        if (colourId !== selectedColorId) {
            setProductColor(colourId);
        }
    };
    return ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Box, { desktop: { spacing: { marginBottom: 2 } }, mobile: { spacing: { marginBottom: 2 } }, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Column, { gutter: false, gutterBottom: true, children: [(0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { inline: true, emphasized: true, children: [t('product:pdp.color'), ":", ' '] }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, dataQA: "color_name", children: description })] }) }), swatches && swatches.length > 1 && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Row, { parent: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { gutter: false, gutterBottom: true, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Group, { valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.XSMALL, dataQA: "color_swatches", children: swatches.map((colour, index) => ((0, jsx_runtime_1.jsx)(cotopaxi_1.ImageRadioButton, { id: `${productCode}-${colour.colourId}__${index}`, name: `color_swatch_${productCode}`, value: colour.colourId.toString(), checked: colour.colourId === selectedColorId, imageUrl: colour.swatch, imageLazyLoad: true, imageWidth: PRODUCT_TILE_SWATCH_DIMENSION, imageHeight: PRODUCT_TILE_SWATCH_DIMENSION, textImage: (0, SwatchUtil_1.getDiscountIconContent)(colour, swatchInformation), textOverlay: true, size: cotopaxi_1.ImageRadioButtonSize.LARGE, title: colour.color, onChange: () => setCurrentProductColor(colour.colourId), icon: (0, SwatchUtil_1.getExtendedRangeIcon)(isShopLanding, colour.extendedRange) }, `${productCode}-${colour.colourId}__${index}`))) }) }) }))] }));
};
exports.default = ColorSwatches;
