"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const common_utils_1 = require("@as-react/common-utils");
const configureStore = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
    if (common_utils_1.Environment.isProduction() && common_utils_1.AppEnvironmentUtil.isProduction()) {
        const configureNoDebugStore = yield Promise.resolve().then(() => tslib_1.__importStar(require(/* webpackMode: "eager" */ './storeConfigure.noDebug')));
        return new configureNoDebugStore.StoreConfiguratorNoDebug();
    }
    const configureLocalDebugStore = yield Promise.resolve().then(() => tslib_1.__importStar(require(
    /* webpackMode: "lazy", webpackChunkName: "saga-debug-local" */ './storeConfigure.localDebug')));
    return new configureLocalDebugStore.StoreConfiguratorLocalDebug();
});
exports.default = configureStore;
