"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductFulfillmentAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const andes_react_1 = require("@yonderland/andes-react");
const ProductFulfillmentAlpha = () => {
    const { checkoutCc: clickAndCollectEnabled } = (0, common_components_1.useAemConfig)();
    const { hdExcluded, ccExcluded, fulfillmentCopyDescription } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillment)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const availableInStoreOnly = ccExcluded && (hdExcluded || (fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded));
    const availableOnlineOnly = ccExcluded && !hdExcluded && !(fulfillmentBySize === null || fulfillmentBySize === void 0 ? void 0 : fulfillmentBySize.storeSourceExcluded);
    if (!clickAndCollectEnabled || !availableInStoreOnly || !availableOnlineOnly || !fulfillmentCopyDescription) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { dataTestId: availableOnlineOnly ? 'check_stock_title' : undefined, children: fulfillmentCopyDescription }));
};
exports.ProductFulfillmentAlpha = ProductFulfillmentAlpha;
