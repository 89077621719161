"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const common_components_1 = require("@as-react/common-components");
const UrlUtil_1 = require("../../../util/UrlUtil");
const generateReviewSchema = (review) => ({
    '@type': 'Review',
    name: review.title,
    author: {
        '@type': 'Person',
        name: review.expertInfo.firstName,
    },
    description: review.heading,
    reviewBody: review.reviewText,
    positiveNotes: review.pros,
    negativeNotes: review.cons,
});
const ProductLD = props => {
    var _a, _b, _c;
    const detailInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDetailInformation)());
    const exportReviews = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetExpertReviews)());
    const { canonicalUrl } = (0, common_components_1.useAemConfig)();
    if (typeof props.data.brand === 'object' &&
        'url' in props.data.brand &&
        ((_b = (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.brand) === null || _b === void 0 ? void 0 : _b.url) === null &&
        ((_c = detailInformation === null || detailInformation === void 0 ? void 0 : detailInformation.brand) === null || _c === void 0 ? void 0 : _c.relativePath)) {
        const url = new URL(canonicalUrl);
        props.data.brand.url = (0, UrlUtil_1.concatUrls)(url.origin, detailInformation.brand.relativePath);
    }
    if (exportReviews.length > 0) {
        props.data.review =
            exportReviews.length > 1 ? exportReviews.map(generateReviewSchema) : generateReviewSchema(exportReviews[0]);
    }
    const jsonLDScript = `<script type="application/ld+json">${JSON.stringify(props.data)}</script>`;
    return (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: jsonLDScript });
};
exports.default = ProductLD;
