"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const ReviewRating_1 = require("../review-rating/ReviewRating");
const ProductActions_1 = require("../../../redux/product/actions/ProductActions");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const ProductDetailV2Container_1 = require("../../../containers/product/ProductDetailV2Container");
const useFeatureToggle_1 = require("../../../hooks/useFeatureToggle");
const ProductDetailInformationHead = ({ brand, description, productCode, review, expertReviews, title, productDetailTabs, }) => {
    const show_sticky_bar_pdp = (0, useFeatureToggle_1.useFeatureToggle)('show_sticky_bar_pdp');
    const dispatch = (0, react_redux_1.useDispatch)();
    const reviewTab = productDetailTabs === null || productDetailTabs === void 0 ? void 0 : productDetailTabs.find(tab => tab.type === common_types_1.ProductDetailTabTypes.REVIEWS);
    const { showTabsAsAccordion } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const isDesktop = (0, cotopaxi_1.useMediaQueryMatchesOnDesktop)();
    const isMobile = (0, cotopaxi_1.useMediaQueryMatchesOnMobile)();
    const isTablet = !isMobile && !isDesktop;
    const onReviewRatingClick = () => {
        const reviewTabEl = document.getElementById(`product_detail_tab_${common_types_1.ProductDetailTabTypes.REVIEWS}`);
        if (reviewTabEl) {
            if (showTabsAsAccordion) {
                const scrollingOffset = ScrollUtil_1.ScrollUtil.getScrollingOffset(isDesktop, isTablet, show_sticky_bar_pdp);
                ScrollUtil_1.ScrollUtil.scrollToPosition(reviewTabEl.parentElement.offsetTop - scrollingOffset, 0, true);
            }
            else {
                ScrollUtil_1.ScrollUtil.scrollToElement(reviewTabEl, false, 'center');
            }
            dispatch(ProductActions_1.productActions.createSetSelectedProductTab(`product_detail_tab_${common_types_1.ProductDetailTabTypes.REVIEWS}`));
        }
    };
    const getHasReviewTab = () => reviewTab &&
        ((review && (reviewTab.showEndUserReview || !!reviewTab.reviewTitle)) ||
            (expertReviews.length > 0 && reviewTab.showExpertReview));
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, tablet: { spacing: { marginBottom: 1.5 } }, desktop: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Heading, { priority: cotopaxi_1.HeadingPriority.H1, type: cotopaxi_1.HeadingType.TITLE, dataQA: "product_name", children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Link, { href: brand.relativePath, children: brand.description }), ' ', (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, type: cotopaxi_1.TextType.TITLE, children: title })] }) }), review && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1.5 } }, children: (0, jsx_runtime_1.jsx)(ReviewRating_1.ReviewRating, { productCode: productCode, review: review, onClick: getHasReviewTab() ? onReviewRatingClick : null }) })), description && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { paragraph: true, children: description }) }))] }));
};
exports.default = ProductDetailInformationHead;
