"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_query_1 = require("@tanstack/react-query");
const queryClient = new react_query_1.QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 1000,
            retry: false,
            networkMode: 'always',
        },
        mutations: {
            networkMode: 'always',
        },
    },
});
exports.default = queryClient;
