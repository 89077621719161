"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityDescriptionAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const useAvailabilityDescription_1 = require("../../../hooks/useAvailabilityDescription");
const AvailabilityDescriptionAlpha = ({ unavailableProduct }) => {
    const { getKeyForText } = (0, useAvailabilityDescription_1.useAvailabilityDescription)(unavailableProduct);
    if (!getKeyForText()) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { dataTestId: "availability_description", variant: "titleL", as: "h3", children: getKeyForText() }));
};
exports.AvailabilityDescriptionAlpha = AvailabilityDescriptionAlpha;
