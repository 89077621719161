"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryPreselectedCardView = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const DeliveryPreselectedCardView = ({ header, content, onEdit, state = 'resting', isEditDisabled, dataTestId = 'preselected_card', }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: state === 'error' ? 'error-bg' : 'quiet', padding: "4", dataTestId: dataTestId, children: (0, jsx_runtime_1.jsx)(andes_react_1.ConditionalWrapper, { condition: !!content, wrapper: children => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [children, content] })), children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", contentAlign: "between", itemsAlign: "start", wrap: "nowrap", children: [(0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { children: header }), !isEditDisabled && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { children: (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { dataTestId: `${dataTestId}_edit`, text: t('checkout.delivery.edit'), onClick: onEdit }) }))] }) }) }));
};
exports.DeliveryPreselectedCardView = DeliveryPreselectedCardView;
