"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelectButtonsAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const ProductUtil_1 = require("../../../util/ProductUtil");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const SizeRecommendationAlpha_1 = require("../size-recommendation-v2/SizeRecommendationAlpha");
const SizeSelectButtonsFullAlpha_1 = require("./SizeSelectButtonsFullAlpha");
const useSelectedSizes_1 = require("../../../hooks/useSelectedSizes");
const SizeSelectButtonsSplittedAlpha_1 = require("./SizeSelectButtonsSplittedAlpha");
const andes_react_1 = require("@yonderland/andes-react");
const SizeSelectButtonsAlpha = ({ id = 'as-size-buttons', sizes, selectedSku, selectSize, basketValidationState, sizeRecommendation, selectedColorId, isOneSizeProduct, isOneSizeProductInline, notifyMeError, sizeCombinationNotExist, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { isShopLanding } = (0, common_components_1.useAemConfig)();
    const isNotifyMeSizeError = notifyMeError === common_types_1.NotifyMeError.SELECT_SIZE;
    const { options, filteredSizes } = (0, ProductUtil_1.mapSizeSelectOptions)(sizes, true, undefined, !isShopLanding, false);
    const { isSizeAndLengthSplitted } = (0, ProductUtil_1.splitSizesBySizeAndLength)(filteredSizes);
    const { sizeOptions, setSizeOptions, selectedSizeOption, setSelectedSizeOption, lengthOptions, setLengthOptions, selectedLengthOption, setSelectedLengthOption, } = (0, useSelectedSizes_1.useSelectedSizes)(options, filteredSizes, selectedColorId, selectedSku, selectSize);
    const [firstSelectedType, setFirstSelectedType] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        if (!firstSelectedType && (selectedSizeOption || selectedLengthOption)) {
            setFirstSelectedType(selectedSizeOption ? 'size' : 'length');
        }
    }, [selectedSizeOption, selectedLengthOption]);
    if (isOneSizeProduct)
        return null;
    if (isOneSizeProductInline) {
        return ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { id: id, children: [sizeRecommendation && (0, jsx_runtime_1.jsx)(SizeRecommendationAlpha_1.SizeRecommendationAlpha, { sizeRecommendation: sizeRecommendation }), (0, jsx_runtime_1.jsx)(andes_react_1.Label, { children: `${t('pdp.size')} ${(_a = options[0]) === null || _a === void 0 ? void 0 : _a.label.toString()}` })] }));
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [sizeRecommendation && (0, jsx_runtime_1.jsx)(SizeRecommendationAlpha_1.SizeRecommendationAlpha, { sizeRecommendation: sizeRecommendation }), !isSizeAndLengthSplitted ? ((0, jsx_runtime_1.jsx)(SizeSelectButtonsFullAlpha_1.SizeSelectButtonsFullAlpha, { id: id, selectedSku: selectedSku, selectSize: selectSize, isNotifyMeSizeError: isNotifyMeSizeError, options: options, filteredSizes: filteredSizes, basketValidationState: basketValidationState })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(SizeSelectButtonsSplittedAlpha_1.SizeSelectButtonsSplittedAlpha, { type: "size", id: `${id}-sizes`, selectedOption: selectedSizeOption, setSelectedOption: setSelectedSizeOption, lengthOptions: lengthOptions, setSizeOptions: setSizeOptions, setLengthOptions: setLengthOptions, sizeOptions: sizeOptions, isNotifyMeSizeError: isNotifyMeSizeError, options: options, filteredSizes: filteredSizes, basketValidationState: basketValidationState, selectedColorId: selectedColorId, setFirstSelectedType: setFirstSelectedType, firstSelectedType: firstSelectedType, sizeCombinationNotExist: sizeCombinationNotExist }), lengthOptions.length > 0 && ((0, jsx_runtime_1.jsx)(SizeSelectButtonsSplittedAlpha_1.SizeSelectButtonsSplittedAlpha, { type: "length", id: `${id}-lengths`, selectedOption: selectedLengthOption, setSelectedOption: setSelectedLengthOption, lengthOptions: lengthOptions, setSizeOptions: setSizeOptions, setLengthOptions: setLengthOptions, sizeOptions: sizeOptions, isNotifyMeSizeError: isNotifyMeSizeError, options: options, filteredSizes: filteredSizes, basketValidationState: basketValidationState, selectedColorId: selectedColorId, setFirstSelectedType: setFirstSelectedType, firstSelectedType: firstSelectedType, sizeCombinationNotExist: sizeCombinationNotExist }))] }))] }));
};
exports.SizeSelectButtonsAlpha = SizeSelectButtonsAlpha;
