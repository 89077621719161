"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const useAvailabilityDescription_1 = require("../hooks/useAvailabilityDescription");
const AvailabilityDescriptionContainer = ({ unavailableProduct, }) => {
    const { getKeyForText } = (0, useAvailabilityDescription_1.useAvailabilityDescription)(unavailableProduct);
    if (!getKeyForText()) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.S, priority: cotopaxi_1.HeadingPriority.H3, children: getKeyForText() }) }));
};
exports.default = AvailabilityDescriptionContainer;
