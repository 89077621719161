"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ColorOutOfStockMessage = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 1, marginBottom: 1 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, children: t('product.colorNotAvailable') }) }));
};
exports.default = ColorOutOfStockMessage;
