"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNewProductOverlayTagColorAndTranslationKey = exports.getProductPriceOverlayTagColorAndContent = exports.getIcon = void 0;
const common_types_1 = require("@as-react/common-types");
const andes_icons_1 = require("@yonderland/andes-icons");
const getIcon = (id) => {
    switch (String(id)) {
        case 'dms':
        case '2669':
            return andes_icons_1.FemaleFillIcon;
        case 'girl':
        case '2617':
            return andes_icons_1.GirlFillIcon;
        case 'hrn':
        case '2668':
            return andes_icons_1.MaleFillIcon;
        case 'boy':
        case '2616':
            return andes_icons_1.BoyFillIcon;
        case 'uni':
        case '2670':
            return andes_icons_1.UnisexFillIcon;
        case 'uni_jr':
        case '2618':
            return andes_icons_1.JuniorFillIcon;
        default:
            return null;
    }
};
exports.getIcon = getIcon;
const tagColorMapping = {
    [common_types_1.MainWebShop.JUTTU]: new Map([
        [1, 'primary'],
        [2, 'neutral'],
        [3, 'active'],
        [4, 'sale'],
        [5, 'sustainability'],
        [6, 'promotion'],
        [7, 'dark'],
    ]),
    [common_types_1.MainWebShop.COTSWOLD]: new Map([
        [1, 'active'],
        [2, 'sustainability'],
        [3, 'sale'],
        [4, 'sustainability'],
        [5, 'dark'],
        [6, 'promotion'],
        [7, 'neutral'],
    ]),
    [common_types_1.MainWebShop.RUNNERSNEED]: new Map([
        [1, 'active'],
        [2, 'dark'],
        [3, 'promotion'],
        [4, 'sale'],
        [5, 'neutral'],
        [6, 'sustainability'],
        [7, 'dark'],
    ]),
    [common_types_1.MainWebShop.BEVER]: new Map([
        [1, 'primary'],
        [2, 'neutral'],
        [3, 'promotion'],
        [4, 'primary'],
        [5, 'dark'],
        [6, 'sale'],
        [7, 'sustainability'],
    ]),
    [common_types_1.MainWebShop.SNOWANDROCK]: new Map([
        [1, 'active'],
        [2, 'sale'],
        [3, 'promotion'],
        [4, 'neutral'],
        [5, 'sustainability'],
        [6, 'dark'],
        [7, 'primary'],
    ]),
    [common_types_1.MainWebShop.ASADVENTURE]: new Map([
        [1, 'primary'],
        [2, 'active'],
        [3, 'active'],
        [4, 'sale'],
        [5, 'promotion'],
        [6, 'sustainability'],
        [7, 'dark'],
    ]),
};
const FALLBACK_TAG_COLOR = 2;
const getProductPriceOverlayTagColorAndContent = (mainWebShop, priceOverlay) => ({
    overlayTagColor: tagColorMapping[mainWebShop].get((priceOverlay === null || priceOverlay === void 0 ? void 0 : priceOverlay.idOverlayBackground) || FALLBACK_TAG_COLOR),
    overlayTagContent: (priceOverlay === null || priceOverlay === void 0 ? void 0 : priceOverlay.overlayBackgroundCodeDescription) || (priceOverlay === null || priceOverlay === void 0 ? void 0 : priceOverlay.overlayCode),
});
exports.getProductPriceOverlayTagColorAndContent = getProductPriceOverlayTagColorAndContent;
const getNewProductOverlayTagColorAndTranslationKey = (mainWebShop) => {
    let tagColor;
    let translationKey;
    switch (mainWebShop) {
        case common_types_1.MainWebShop.JUTTU:
            tagColor = 'primary';
            translationKey = 'product.new.label.2';
            break;
        case common_types_1.MainWebShop.COTSWOLD:
            tagColor = 'sale';
            translationKey = 'product.new.label.3';
            break;
        case common_types_1.MainWebShop.RUNNERSNEED:
            tagColor = 'promotion';
            translationKey = 'product.new.label.1';
            break;
        case common_types_1.MainWebShop.BEVER:
            tagColor = 'primary';
            translationKey = 'product.new.label.1';
            break;
        case common_types_1.MainWebShop.SNOWANDROCK:
            tagColor = 'sale';
            translationKey = 'product.new.label.1';
            break;
        default:
            tagColor = 'active';
            translationKey = 'product.new.label.1';
            break;
    }
    return {
        tagColor,
        translationKey,
    };
};
exports.getNewProductOverlayTagColorAndTranslationKey = getNewProductOverlayTagColorAndTranslationKey;
