"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelectButtonsSplittedAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ProductConstants_1 = require("../../../constants/ProductConstants");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const SizeSelectButtonsSplittedAlpha = ({ type, id, selectedOption, setSelectedOption, lengthOptions, setLengthOptions, setSizeOptions, sizeOptions, isNotifyMeSizeError, options, filteredSizes, basketValidationState, selectedColorId, setFirstSelectedType, firstSelectedType, sizeCombinationNotExist, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { isLg, isXl, is2xl } = (0, andes_react_1.useBreakpoints)();
    const isDesktop = isLg || isXl || is2xl;
    const { sizesMap, lengthsMap } = (0, ProductUtil_1.splitSizesBySizeAndLength)(filteredSizes);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)();
    const isSize = type === 'size';
    const typeMap = isSize ? sizesMap : lengthsMap;
    const typeOptions = isSize ? sizeOptions : lengthOptions;
    const withNotifyMe = typeOptions.some(option => option.isNotifyMe);
    const chipSize = `${Math.max(...[...typeMap.keys()].map(s => s.length)) + (withNotifyMe ? 4 : 2)}ch + ${ProductConstants_1.PRODUCT_SIZE_CHIP_INNER_PADDING + (withNotifyMe ? 6 : 0)}px`;
    const getSizeButtonState = (option, selected) => {
        if (errorMessage &&
            !option.disabled &&
            (!option.isNotifyMe || (!option.isSoldOut && isNotifyMeSizeError)) &&
            !selectedOption) {
            return 'error';
        }
        if (option.label === selected) {
            return 'activated';
        }
        return 'resting';
    };
    const handleButtonClick = (option) => () => {
        setErrorMessage(undefined);
        setSelectedOption(option.label.toString());
        if (!firstSelectedType) {
            setFirstSelectedType(type);
        }
        const updatedOptions = (0, ProductUtil_1.getActiveSizeOptions)(typeMap.get(option.label.toString()) || [], options, isSize ? lengthOptions : sizeOptions, isSize ? 'length' : 'size');
        if (isSize) {
            setLengthOptions(updatedOptions);
        }
        else {
            setSizeOptions(updatedOptions);
        }
    };
    (0, react_1.useEffect)(() => {
        setErrorMessage(undefined);
    }, [selectedColorId]);
    (0, react_1.useEffect)(() => {
        if ((basketValidationState === common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT ||
            basketValidationState === common_types_1.BasketButtonError.SKU ||
            isNotifyMeSizeError) &&
            !selectedOption) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(id), isDesktop, () => setErrorMessage((0, ProductUtil_1.getSizeButtonsError)(basketValidationState, type)));
        }
    }, [basketValidationState, selectedOption, isNotifyMeSizeError, sizeCombinationNotExist]);
    const buttonOptions = (0, react_1.useMemo)(() => typeOptions.map(option => ((0, jsx_runtime_1.jsx)(andes_react_1.Chip, { state: getSizeButtonState(option, selectedOption), iconLeft: option.isNotifyMe && type !== firstSelectedType ? { source: andes_icons_1.EnvelopeSimpleIcon } : undefined, text: option.label, strikethrough: type !== firstSelectedType && ((option.isSoldOut && option.isNotifyMe) || option.disabled), onClick: handleButtonClick(option), size: "md", variant: "primary" }, option.label))), [options, sizeOptions, lengthOptions, errorMessage]);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { id: id, dataTestId: id, gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: t(`pdp.size.buttons.${type}`) }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Grid, { scalable: {
                    min: `calc(${chipSize})`,
                    maxCols: typeOptions.length <= 2 ? { mobile: 2, desktop: 3 } : { mobile: 4, desktop: 6 },
                    repeatPattern: typeOptions.length <= 2 ? 'auto-fill' : undefined,
                }, gap: "s", dataQA: `${type}-select-buttons`, children: buttonOptions }), errorMessage && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { itemsAlign: "center", gap: "1", children: [(0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.InfoIcon, color: "error", size: "xs" }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "error", children: t(errorMessage) })] }))] }));
};
exports.SizeSelectButtonsSplittedAlpha = SizeSelectButtonsSplittedAlpha;
