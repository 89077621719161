"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeRecommendationAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const SizeRecommendationAlpha = ({ sizeRecommendation }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isModelInfoMessage = Boolean((sizeRecommendation === null || sizeRecommendation === void 0 ? void 0 : sizeRecommendation.modelHeight) && (sizeRecommendation === null || sizeRecommendation === void 0 ? void 0 : sizeRecommendation.modelSize));
    const isSizingAdviceMessage = Boolean(sizeRecommendation === null || sizeRecommendation === void 0 ? void 0 : sizeRecommendation.sizingAdvice);
    if (!isModelInfoMessage && !isSizingAdviceMessage) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { dataTestId: "size_recommendation", paddingInline: { xs: '4', md: '3' }, paddingBlock: "2", backgroundColor: "quiet", children: [isModelInfoMessage && ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [t('product.size.recommendation.model.info.part1'), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, as: "span", children: sizeRecommendation.modelHeight }), t('product.size.recommendation.model.info.part2'), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, as: "span", children: sizeRecommendation.modelSize }), t('product.size.recommendation.model.info.part3')] })), isSizingAdviceMessage && (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: sizeRecommendation.sizingAdvice })] }));
};
exports.SizeRecommendationAlpha = SizeRecommendationAlpha;
