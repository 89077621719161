"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPriceAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const andes_react_1 = require("@yonderland/andes-react");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const usePriceViewModel_1 = require("../../../hooks/usePriceViewModel");
const ProductUtil_1 = require("../../../util/ProductUtil");
const ProductPriceAlpha = () => {
    var _a;
    const { currency } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const priceInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPriceInformation)());
    const dynamicSavingsInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDynamicSavingsInformation)());
    const priceViewModel = (0, usePriceViewModel_1.usePriceViewModel)({
        prices: (priceInformation || {}).prices,
        hasDiscount: Boolean(priceInformation === null || priceInformation === void 0 ? void 0 : priceInformation.hasDiscount),
    });
    const productDiscount = (0, ProductUtil_1.getProductDiscount)(dynamicSavingsInformation.productDiscountAmount, dynamicSavingsInformation.productDiscountPercentage, currency);
    if (!((_a = priceInformation === null || priceInformation === void 0 ? void 0 : priceInformation.prices) === null || _a === void 0 ? void 0 : _a.SELL))
        return null;
    const productPriceProps = Object.assign(Object.assign({ dataTestId: 'product_price', currency }, priceViewModel), { tag: (priceInformation === null || priceInformation === void 0 ? void 0 : priceInformation.hasDiscount) && productDiscount
            ? { text: `${t(`${i18nConstants_1.I18N_NAMESPACES.product}:pdp.dynamic.savings`)} ${productDiscount}` }
            : undefined, size: 'lg' });
    return (0, jsx_runtime_1.jsx)(andes_react_1.Price, Object.assign({}, productPriceProps));
};
exports.ProductPriceAlpha = ProductPriceAlpha;
