"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToBasketButtonAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const AddToBasketButtonAlpha = ({ onClick, quantity = 1, isStickyBar, isOneSizeProduct, disabled = false, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { isLg, isXl, is2xl } = (0, andes_react_1.useBreakpoints)();
    const isDesktop = isLg || isXl || is2xl;
    const hideText = !isDesktop && isStickyBar && !isOneSizeProduct;
    const fullWidth = !isStickyBar || (isStickyBar && isOneSizeProduct);
    const icon = {
        source: andes_icons_1.ShoppingCartIcon,
        size: 'sm',
    };
    const handleClick = () => {
        onClick(quantity);
    };
    return ((0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { theme: "branded", variant: "primary", state: disabled ? 'disabled' : 'interactive', fullWidth: fullWidth, dataTestId: "add_to_basket", size: "md", onClick: handleClick, text: !hideText && t('product:pdp.cart.addtobasket.btn'), iconRight: hideText ? icon : undefined, iconLeft: !hideText ? icon : undefined }));
};
exports.AddToBasketButtonAlpha = AddToBasketButtonAlpha;
