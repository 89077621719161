"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToWishlistButtonAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const HtmlElementUtil_1 = require("../../../util/HtmlElementUtil");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const AddToWishlistButtonAlpha = ({ isProductInWishList, onToggle, wishListProduct, isStickyBar, buttonProps, }) => {
    const [animate, setAnimate] = (0, react_1.useState)(false);
    const { isXs, isSm } = (0, andes_react_1.useBreakpoints)();
    const isDesktop = !(isXs || isSm);
    const isClient = (0, andes_react_1.useIsClient)();
    const toggleWishListProduct = () => {
        onToggle(wishListProduct);
        setAnimate(!isProductInWishList);
        !isDesktop && (0, HtmlElementUtil_1.showMobileHeader)();
    };
    return ((0, jsx_runtime_1.jsx)(andes_react_1.IconButton, Object.assign({ dataTestId: "add_to_wishlist", theme: "branded", size: "md", inverse: true, icon: {
            source: isClient && isProductInWishList ? andes_icons_1.HeartFillIcon : andes_icons_1.HeartIcon,
            size: isStickyBar ? 'xs' : 'sm',
            animation: animate ? 'pulse' : undefined,
        }, onClick: toggleWishListProduct }, buttonProps)));
};
exports.AddToWishlistButtonAlpha = AddToWishlistButtonAlpha;
