"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductFeature = ({ feature, isFirstFeature }) => {
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: isFirstFeature ? 0 : 3 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, gutter: cotopaxi_1.GroupGutter.NONE, valign: cotopaxi_1.GroupVerticalAlign.CENTER, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { flex: { shrink: 0 }, mobile: { spacing: { marginRight: 2 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { src: feature.icon, size: cotopaxi_1.ImageSize.PRODUCT_FEATURE }) }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { emphasized: true, children: typeof feature.featureValue === 'string'
                                ? feature.title.replace('{value}', feature.featureValue)
                                : feature.title }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: feature.description })] })] }) }));
};
exports.default = ProductFeature;
