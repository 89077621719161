"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceOrderTrackingOverview = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const andes_react_1 = require("@yonderland/andes-react");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ServiceOrderTrackingOverview = ({ serviceOrder, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.account);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { backgroundColor: "quiet", paddingInline: "14", paddingBlock: "8", flex: { grow: 1 }, children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleM", children: t('tracking.order.overview.title') }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "6", children: (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "6", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "titleM", children: t('tracking.order.overview.items.ready', {
                        ready: serviceOrder.readyProducts,
                        count: serviceOrder.totalProducts,
                    }) }) }), (_a = serviceOrder.products) === null || _a === void 0 ? void 0 : _a.map((product, index) => {
                var _a, _b, _c, _d;
                const productTile = {
                    brand: product.brandName,
                    title: t((_a = product.productType) === null || _a === void 0 ? void 0 : _a.publicDisplayName),
                };
                return ((0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTileHorizontal, { product: productTile, gap: "0", children: (0, jsx_runtime_1.jsxs)(andes_react_1.ProductTile.Cell, { section: "content", children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Title, { brandOnTheSameLine: true }), (_b = product.locations) === null || _b === void 0 ? void 0 : _b.map((location, indexLocation) => ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t(location) }, `${location}-${index}-${indexLocation}`))), (0, jsx_runtime_1.jsxs)(andes_react_1.Box, { paddingBlockStart: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "subtle", children: product.label }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: product.inProgress ? 'success' : 'error', strong: true, alignment: "end", children: t(product.inProgress
                                                    ? 'tracking.order.product.status.in.progress'
                                                    : (_c = product.status) === null || _c === void 0 ? void 0 : _c.publicDisplayName) })] })] }) }), index < ((_d = serviceOrder.products) === null || _d === void 0 ? void 0 : _d.length) - 1 && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlock: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}) }))] }, product.label));
            })] }));
};
exports.ServiceOrderTrackingOverview = ServiceOrderTrackingOverview;
