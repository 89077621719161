"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const FlipClock_1 = tslib_1.__importStar(require("../flip-clock/FlipClock"));
const Countdown = props => {
    const { theme, size, endDateTime } = props;
    return ((0, jsx_runtime_1.jsx)(FlipClock_1.default, { dateTime: endDateTime, countdown: true, clockFace: FlipClock_1.FlipClockFace.DAILY_COUNTER, theme: theme, size: size }));
};
exports.default = Countdown;
