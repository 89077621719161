"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeRecommendationV2 = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const SizeRecommendationV2 = ({ sizeRecommendation }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const isModelInfoMessage = Boolean((sizeRecommendation === null || sizeRecommendation === void 0 ? void 0 : sizeRecommendation.modelHeight) && (sizeRecommendation === null || sizeRecommendation === void 0 ? void 0 : sizeRecommendation.modelSize));
    const isSizingAdviceMessage = Boolean(sizeRecommendation === null || sizeRecommendation === void 0 ? void 0 : sizeRecommendation.sizingAdvice);
    if (!isModelInfoMessage && !isSizingAdviceMessage) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { dataAttributes: { 'data-qa': 'size_recommendation' }, mobileBleed: true, mobile: { spacing: { marginBottom: 1.5 } }, tablet: { spacing: { marginBottom: 1 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.SMALL, children: [isModelInfoMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: { paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 1 },
                        theme: cotopaxi_1.BoxTheme.PRIMARY,
                    }, tablet: { spacing: { paddingLeft: 1.5, paddingRight: 1.5 } }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { children: [t('product.size.recommendation.model.info.part1'), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, size: cotopaxi_1.TextSize.INHERIT, children: sizeRecommendation.modelHeight }), t('product.size.recommendation.model.info.part2'), (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { inline: true, emphasized: true, size: cotopaxi_1.TextSize.INHERIT, children: sizeRecommendation.modelSize }), t('product.size.recommendation.model.info.part3')] }) })), isSizingAdviceMessage && ((0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                        spacing: { paddingLeft: 2, paddingRight: 2, paddingBottom: 1, paddingTop: 1 },
                        theme: cotopaxi_1.BoxTheme.PRIMARY,
                    }, tablet: { spacing: { paddingLeft: 1.5, paddingRight: 1.5 } }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { children: sizeRecommendation.sizingAdvice }) }))] }) }));
};
exports.SizeRecommendationV2 = SizeRecommendationV2;
