"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistrationForm = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const Icons = tslib_1.__importStar(require("@yonderland/andes-icons"));
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const Yup = tslib_1.__importStar(require("yup"));
const FormField_1 = require("../../../form/FormField");
const i18nConstants_1 = require("../../../../constants/i18nConstants");
const GuestLoginBlock_1 = require("./GuestLoginBlock");
const useGuestLogin_1 = require("../../../../hooks/useGuestLogin");
const CheckoutContext_1 = require("../../../../context/CheckoutContext");
const RegistrationForm = ({ onEditEmailClick, onSubmit }) => {
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const validationSchema = Yup.object({
        password: Yup.string()
            .min(common_types_1.DefaultSettings.MIN_LENGTH_PASSWORD, `${t(`${i18nConstants_1.I18N_NAMESPACES.account}:password.indicator.strength.label`)}${t(`${i18nConstants_1.I18N_NAMESPACES.account}:password.indicator.strength.short`)}`)
            .required(t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED)),
        passwordConfirmation: Yup.string()
            .required(t(common_types_1.ErrorMessage.ACCOUNT_REQUIRED))
            .oneOf([Yup.ref('password'), ''], t(common_types_1.ErrorMessage.PASSWORD_MATCH)),
    });
    const { mainWebShop } = (0, common_components_1.useAemConfig)();
    const [showPassword, setShowPassword] = (0, react_1.useState)(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = (0, react_1.useState)(false);
    const [state, dispatch] = (0, CheckoutContext_1.useCheckoutState)();
    const { email } = state.login;
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: { password: '', passwordConfirmation: '' },
        mode: 'onTouched',
        resolver: (0, yup_1.yupResolver)(validationSchema),
    });
    const { guestLogin, errorMessage, isPending: isGuestLoginPending } = (0, useGuestLogin_1.useGuestLogin)();
    const handleGuestLoginClick = () => guestLogin(email);
    const guestError = t(errorMessage || '');
    const handleSubmit = ({ password }) => {
        dispatch({ type: 'update_login_data', payload: { email, password } });
        onSubmit();
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), name: "registration-form", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "6", children: [(0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "headingS", color: "emphasised", children: t([
                                    `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.title.${mainWebShop}`,
                                    `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.title`,
                                ]) }), (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: t([
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.intro.${mainWebShop}`,
                                        `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.intro`,
                                    ]) }) })] }), guestError && (0, jsx_runtime_1.jsx)(andes_react_1.Banner, { text: guestError, status: "error" }), (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { strong: true, wordBreak: "break", children: [email, ' - ', (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.edit`), onClick: onEditEmailClick })] }), (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "password", required: true, children: ({ fieldProps, error }) => ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { label: t(`${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.password.label`), name: "password", id: "password", error: error, type: showPassword ? 'text' : 'password', adornmentEnd: (0, jsx_runtime_1.jsx)(andes_react_1.IconButton, { size: "sm", variant: "plain", onClick: () => setShowPassword(prev => !prev), icon: { source: showPassword ? Icons.EyeFillIcon : Icons.EyeSlashFillIcon, color: 'subtle' } }) }))) }), (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "passwordConfirmation", required: true, children: ({ fieldProps, error }) => ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, fieldProps, { label: t([
                                `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.password_confirmation.label.${mainWebShop}`,
                                `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.password_confirmation.label`,
                            ]), name: "passwordConfirmation", id: "passwordConfirmation", error: error, type: showPasswordConfirmation ? 'text' : 'password', adornmentEnd: (0, jsx_runtime_1.jsx)(andes_react_1.IconButton, { size: "sm", variant: "plain", onClick: () => setShowPasswordConfirmation(prev => !prev), icon: {
                                    source: showPasswordConfirmation ? Icons.EyeFillIcon : Icons.EyeSlashFillIcon,
                                    color: 'subtle',
                                } }) }))) }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { type: "submit", theme: "branded", text: t([
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.action.${mainWebShop}`,
                            `${i18nConstants_1.I18N_NAMESPACES.checkout}:checkout.create_account.action`,
                        ]), fullWidth: true }), (0, jsx_runtime_1.jsx)(GuestLoginBlock_1.GuestLoginBlock, { isLoading: isGuestLoginPending, onGuestLoginClick: handleGuestLoginClick })] }) }) })));
};
exports.RegistrationForm = RegistrationForm;
