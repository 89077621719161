"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductVariantButtonsAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const common_utils_1 = require("@as-react/common-utils");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const ProductVariantButtonsAlpha = ({ productVariants, handleVariantClick, productCode, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    (0, react_1.useEffect)(() => {
        productVariants.map(({ productCodeVariants }, vIndex) => productCodeVariants.map((_, cIndex) => (0, common_utils_1.setTooltip)({
            element: `#product-variant-button-${vIndex}-disabled-${cIndex}`,
            content: t('pdp.combination.not.available'),
            showCloseButton: false,
            optionsOverride: {
                placement: 'bottom',
            },
        })));
    }, []);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "2", children: productVariants.map(({ variantType, productCodeVariants }, vIndex) => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: `${variantType}:` }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Grid, { scalable: {
                        min: `${Math.max(...productCodeVariants.map(code => code.variantValue.length))}ch`,
                        maxCols: productCodeVariants.length <= 2 ? { mobile: 2, desktop: 3 } : { mobile: 4, desktop: 5 },
                        repeatPattern: productCodeVariants.length <= 2 ? 'auto-fill' : undefined,
                    }, gap: "s", dataQA: "product-variant-buttons", children: productCodeVariants.map((variant, cIndex) => {
                        const id = `product-variant-button-${vIndex}-${variant.productCode || `disabled-${cIndex}`}`;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.Chip, { dataTestId: id, id: id, text: variant.variantValue, state: !variant.productCode ? 'disabled' : productCode === variant.productCode ? 'activated' : 'resting', strikethrough: !variant.productCode, onClick: variant.productCode && variant.productSeoUrl && productCode !== variant.productCode
                                ? handleVariantClick(variant)
                                : undefined, size: "md", variant: "primary" }, variant.productCode || `${variantType}-${cIndex}`));
                    }) })] }))) }));
};
exports.ProductVariantButtonsAlpha = ProductVariantButtonsAlpha;
