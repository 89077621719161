"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdvantagesBlockAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const AdvantagesBlockAlpha = ({ advantages }) => {
    if (!(advantages === null || advantages === void 0 ? void 0 : advantages.length))
        return null;
    const renderContent = (item) => item.link ? ((0, jsx_runtime_1.jsx)(andes_react_1.Link, { href: item.link, target: item.linkOpensInNewTab ? '_blank' : undefined, children: item.text })) : (item.text);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { dataTestId: "advantages_block", paddingInline: "4", paddingBlock: "3", backgroundColor: "quiet", children: (0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "2", children: advantages.map(item => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", wrap: "nowrap", children: [item.icon && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", flex: { shrink: 0 }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Icon, { name: item.icon, size: cotopaxi_1.IconSize.MINOR }) })), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: renderContent(item) })] }, item.text.replace(/ /g, '')))) }) }));
};
exports.AdvantagesBlockAlpha = AdvantagesBlockAlpha;
