"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authActions = void 0;
const ActionEnums_1 = require("../../../enums/ActionEnums");
exports.authActions = {
    getUserAuthState: () => ({
        type: ActionEnums_1.AuthActionTypes.GET_USER_AUTH_STATE,
    }),
    updateUserAuthState: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.UPDATE_USER_AUTH_STATE,
        payload,
    }),
    createInitSocialAction: () => ({
        type: ActionEnums_1.AuthActionTypes.INIT_SOCIAL,
    }),
    createLoadSocialSuccessAction: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.LOAD_SOCIAL_SUCCESS,
        payload,
    }),
    createSocialLoginAction: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.SOCIAL_LOGIN,
        payload,
    }),
    login: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN,
        payload,
    }),
    registeredLogin: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.REGISTERED_LOGIN,
        payload,
    }),
    fastCheckout: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.FAST_CHECKOUT,
        payload,
    }),
    loginSuccess: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_SUCCESS,
        payload,
    }),
    loginFail: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_FAIL,
        payload,
    }),
    logout: () => ({
        type: ActionEnums_1.AuthActionTypes.LOGOUT,
    }),
    logoutSuccess: () => ({
        type: ActionEnums_1.AuthActionTypes.LOGOUT_SUCCESS,
    }),
    resetError: () => ({
        type: ActionEnums_1.AuthActionTypes.RESET_ERROR,
    }),
    createResetCredentialsAction: (email) => ({
        type: ActionEnums_1.AuthActionTypes.RESET_PASSWORD,
        payload: email,
    }),
    createResetCredentialsErrorAction: (message) => ({
        type: ActionEnums_1.AuthActionTypes.RESET_PASSWORD_ERROR,
        payload: message,
    }),
    createResetCredentialsSuccessAction: (message) => ({
        type: ActionEnums_1.AuthActionTypes.RESET_PASSWORD_SUCCESS,
        payload: message,
    }),
    createLoginGuestAction: (userName, exploreMoreCardNumber, customerUUID) => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_GUEST,
        payload: { userName, exploreMoreCardNumber, customerUUID },
    }),
    createLoginGuestSuccessAction: () => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_GUEST_SUCCESS,
    }),
    createLoginGuestFailAction: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_GUEST_FAIL,
        payload,
    }),
    createLoginReturnGuestAction: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_RETURN_GUEST,
        payload,
    }),
    createLoginReturnGuestSuccessAction: () => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_RETURN_GUEST_SUCCESS,
    }),
    createLoginReturnGuestFailAction: (payload) => ({
        type: ActionEnums_1.AuthActionTypes.LOGIN_RETURN_GUEST_FAIL,
        payload,
    }),
};
