"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifyMeFormAlpha = exports.NOTIFY_ME_FORM_NAME = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const common_types_1 = require("@as-react/common-types");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const i18nConstants_1 = require("../../constants/i18nConstants");
const SessionSelector_1 = require("../../redux/session/selectors/SessionSelector");
const ProductSelector_1 = require("../../redux/product/selectors/ProductSelector");
const ProductActions_1 = require("../../redux/product/actions/ProductActions");
const AnalyticsActions_creators_1 = require("../../redux/analytics/actions/AnalyticsActions.creators");
const react_hook_form_1 = require("react-hook-form");
const andes_react_1 = require("@yonderland/andes-react");
const FormField_1 = require("../form/FormField");
const EmailValidationWrapper_1 = require("../form/validators/EmailValidationWrapper");
const andes_icons_1 = require("@yonderland/andes-icons");
const DeliveryPromiseExtendedAlpha_1 = require("../product-detail/delivery-promise-extended/DeliveryPromiseExtendedAlpha");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const emailFieldName = 'notifyMeEmail';
exports.NOTIFY_ME_FORM_NAME = 'notify-me';
const NotifyMeFormAlpha = ({ withNewDesign }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.account, i18nConstants_1.I18N_NAMESPACES.general, i18nConstants_1.I18N_NAMESPACES.product]);
    const { defaultRequestParams, servicesEndpoint, privacyPolicyLink } = (0, common_components_1.useAemConfig)();
    const [submittedEmail, setSubmittedEmail] = (0, react_1.useState)();
    const [emailFieldFocused, setEmailFieldFocused] = (0, react_1.useState)(false);
    const [shouldNotResetStateOnSizeChange, setShouldNotResetStateOnSizeChange] = (0, react_1.useState)(false);
    const dispatch = (0, react_redux_1.useDispatch)();
    const initialEmail = (0, react_redux_1.useSelector)((0, SessionSelector_1.makeGetUserEmail)()) || '';
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const { productId } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductInfo)());
    const { postNotifyMeSubscribe } = (0, common_queries_1.productMutations)({ servicesEndpoint, defaultRequestParams });
    const { mutate: postNotifyMeSubscribeMutation, isPending, isError } = (0, react_query_1.useMutation)(postNotifyMeSubscribe);
    (0, react_1.useEffect)(() => {
        if (shouldNotResetStateOnSizeChange) {
            setShouldNotResetStateOnSizeChange(false);
        }
        else {
            setSubmittedEmail(undefined);
            setEmailFieldFocused(false);
            formProps.reset();
        }
    }, [selectedSku]);
    const formProps = (0, react_hook_form_1.useForm)({
        defaultValues: {
            notifyMeEmail: initialEmail,
        },
        mode: 'onTouched',
    });
    const handleSubmit = (_a) => tslib_1.__awaiter(void 0, [_a], void 0, function* ({ notifyMeEmail }) {
        if (submittedEmail === notifyMeEmail || isPending) {
            return;
        }
        if (!selectedSku) {
            setShouldNotResetStateOnSizeChange(true);
            dispatch(ProductActions_1.productActions.setNotifyMeError(common_types_1.NotifyMeError.SELECT_SIZE));
            return;
        }
        postNotifyMeSubscribeMutation({
            productId: Number(productId),
            skuId: Number(selectedSku),
            email: notifyMeEmail,
        }, {
            onSuccess: () => {
                dispatch(AnalyticsActions_creators_1.analyticsActions.notifyMeRequest());
                setSubmittedEmail(notifyMeEmail);
            },
        });
    });
    const handleClearEmail = () => {
        formProps.setValue(emailFieldName, '');
    };
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, Object.assign({}, formProps, { children: (0, jsx_runtime_1.jsx)("form", { onSubmit: formProps.handleSubmit(handleSubmit), noValidate: true, name: "notify-me-form", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { marginInline: withNewDesign ? `-${DeliveryPromiseExtendedAlpha_1.CONTAINER_PADDING}` : undefined, children: isError ? ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "error", text: t(common_types_1.ErrorMessage.API_ERROR) })) : submittedEmail ? ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "success", text: t('product:notifyMe.success.message', { email: submittedEmail }) })) : ((0, jsx_runtime_1.jsx)(andes_react_1.Banner, { status: "informative", text: t('product:notifyMe.instruction') })) }), (0, jsx_runtime_1.jsx)(EmailValidationWrapper_1.EmailValidationWrapper, { emailFieldKey: "email", errorMessages: {
                            invalidEmail: t('account.subscribe.invalid'),
                            required: t('account.subscribe.invalid'),
                        }, children: ({ validate, renderError }) => ((0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: emailFieldName, validate: validate, renderError: renderError, children: (_a) => {
                                var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                                return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { label: t('product:notifyMe.email.label'), dataTestId: "notify_me_email_address", id: "notify_me_email_address", autoComplete: "email", error: error, maxLength: 100, placeholder: t('product:notifyMe.email.placeholder'), adornmentEnd: !emailFieldFocused && options.value === submittedEmail ? ((0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.CheckIcon })) : (options.value && ((0, jsx_runtime_1.jsx)(andes_react_1.IconButton, { variant: "plain", icon: { source: andes_icons_1.XIcon }, size: "sm", onClick: handleClearEmail }))) })));
                            } })) }), (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { dataTestId: "notify_me_submit_button", theme: "branded", variant: "primary", size: "md", fullWidth: true, type: "submit", state: isPending ? 'loading' : 'interactive', text: t('product:notifyMe.submit.button'), iconLeft: {
                            source: andes_icons_1.EnvelopeSimpleIcon,
                            size: 'md',
                        } }), Boolean(privacyPolicyLink) && ((0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subtle", children: [t('product:notifyMe.privacy.policy.part1'), (0, jsx_runtime_1.jsx)(andes_react_1.Link, { href: privacyPolicyLink, size: "sm", variant: "primary", target: "_blank", children: t('product:notifyMe.privacy.policy.part2') }), t('product:notifyMe.privacy.policy.part3')] }))] }) }) })));
};
exports.NotifyMeFormAlpha = NotifyMeFormAlpha;
