"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPlacementCopyBoxAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_utils_1 = require("@as-react/common-utils");
const HtmlInclude_1 = require("../../common/html-include/HtmlInclude");
const useProductPlacementCopyBox_1 = require("./useProductPlacementCopyBox");
const andes_react_1 = require("@yonderland/andes-react");
const ProductPlacementCopyBoxAlpha = ({ ppc }) => {
    const { content, renderedContainerElement, isExternal } = (0, useProductPlacementCopyBox_1.useProductPlacementCopyBox)(ppc);
    if ((0, common_utils_1.isNullOrUndefined)(content) || content.length === 0)
        return null;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { dataTestId: "product_placement_copy", marginInline: isExternal() ? '-2' : undefined, paddingBlock: "2", paddingInline: isExternal()
            ? undefined
            : {
                xs: '4',
                sm: '2',
            }, backgroundColor: isExternal() ? undefined : 'warning-bg', children: (0, jsx_runtime_1.jsx)("div", { ref: renderedContainerElement, children: (0, jsx_runtime_1.jsx)(HtmlInclude_1.HtmlInclude, { htmlFragment: content }) }) }));
};
exports.ProductPlacementCopyBoxAlpha = ProductPlacementCopyBoxAlpha;
