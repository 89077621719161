"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SizeSelectButtonsFullAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ProductConstants_1 = require("../../../constants/ProductConstants");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const andes_react_1 = require("@yonderland/andes-react");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_icons_1 = require("@yonderland/andes-icons");
const SizeSelectButtonsFullAlpha = ({ id, selectedSku, selectSize, isNotifyMeSizeError, options, filteredSizes, basketValidationState, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { isLg, isXl, is2xl } = (0, andes_react_1.useBreakpoints)();
    const isDesktop = isLg || isXl || is2xl;
    const selectedSize = selectedSku && filteredSizes.find(option => option.sku === selectedSku);
    const [errorMessage, setErrorMessage] = (0, react_1.useState)();
    const chipSize = `${Math.max(...options.map(s => s.label.toString().length)) + 2}ch + ${ProductConstants_1.PRODUCT_SIZE_CHIP_INNER_PADDING}px`;
    (0, react_1.useEffect)(() => {
        if (basketValidationState === common_types_1.BasketButtonError.SKU_OPEN_SIZE_SELECT ||
            basketValidationState === common_types_1.BasketButtonError.SKU ||
            isNotifyMeSizeError) {
            ScrollUtil_1.ScrollUtil.scrollToErrorElement(document.getElementById(id), isDesktop, () => {
                setErrorMessage((0, ProductUtil_1.getSizeButtonsError)(basketValidationState));
            });
        }
    }, [basketValidationState]);
    const getButtonState = (option) => {
        if (option.disabled)
            return 'disabled';
        if (errorMessage && (!option.isSoldOut || (option.isSoldOut && isNotifyMeSizeError)))
            return 'error';
        if (option.value === selectedSku)
            return 'activated';
        return 'resting';
    };
    const handleButtonClick = (option) => () => {
        if (option.disabled)
            return;
        setErrorMessage(undefined);
        selectSize({ sku: option.value.toString(), isSoldOut: option.isSoldOut });
    };
    const buttonOptions = (0, react_1.useMemo)(() => options.map(option => ((0, jsx_runtime_1.jsx)(andes_react_1.Chip, { state: getButtonState(option), text: option.label, strikethrough: option.isSoldOut, onClick: option.disabled ? undefined : handleButtonClick(option), size: "md" }, option.label))), [options]);
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { id: id, dataTestId: id, gap: "2", children: [options.length ? (selectedSize &&
                selectedSize.fullCommercialSize &&
                !options.find(size => size.label === selectedSize.fullCommercialSize) ? ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", itemsAlign: "baseline", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: t('pdp.size.buttons.label') }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: selectedSize.fullCommercialSize })] })) : ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, children: t('pdp.size.buttons.label') }))) : null, (0, jsx_runtime_1.jsx)(cotopaxi_1.Grid, { scalable: {
                    min: `calc(${chipSize})`,
                    maxCols: options.length <= 2 ? { mobile: 2, desktop: 3 } : { mobile: 4, desktop: 6 },
                    repeatPattern: options.length <= 2 ? 'auto-fill' : undefined,
                }, gap: "s", dataQA: "size-select-buttons", children: buttonOptions }), errorMessage && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { itemsAlign: "center", gap: "1", children: [(0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.InfoIcon, color: "error", size: "xs" }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "error", children: t(errorMessage) })] }))] }));
};
exports.SizeSelectButtonsFullAlpha = SizeSelectButtonsFullAlpha;
