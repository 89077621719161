"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityInCurrentStoreTable = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const ImageUtil_1 = require("../../util/ImageUtil");
const AvailabilityInCurrentStoreTable = ({ productCode, productSizes, productColors, selectedSku, isOneSizeProduct, productImageCdnDomains, onCellClick, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const colorsTable = ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Table, { striped: true, fixedLayout: true, variant: cotopaxi_1.TableVariant.STOCK_AVAILABILITY, children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsx)("tr", { children: (0, jsx_runtime_1.jsx)("th", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    marginLeft: 5,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, emphasized: true, children: t('pdp.color') }) }) }) }) }), (0, jsx_runtime_1.jsx)("tbody", { children: productColors.map((color) => ((0, jsx_runtime_1.jsx)("tr", { children: (0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { inline: true, valign: cotopaxi_1.GroupVerticalAlign.CENTER, gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Image, { lazy: true, circle: true, size: cotopaxi_1.ImageSize.STOCK_AVAILABILITY_SWATCH, src: (0, ImageUtil_1.getSwatchUrlByProductCode)(productCode, color.swatch, productImageCdnDomains), alt: color.description }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                        spacing: {
                                            paddingLeft: 0.5,
                                        },
                                    }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, children: [color.colorCode, " - ", color.description] }) })] }) }) }, color.colorId))) })] }));
    const skuTable = ((0, jsx_runtime_1.jsxs)(cotopaxi_1.Table, { striped: true, responsive: true, variant: cotopaxi_1.TableVariant.STOCK_AVAILABILITY, firstColumnAlign: cotopaxi_1.TableAlign.CENTER, children: [(0, jsx_runtime_1.jsx)("thead", { children: (0, jsx_runtime_1.jsx)("tr", { children: productSizes.map((size) => ((0, jsx_runtime_1.jsx)("th", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: {
                                spacing: {
                                    paddingLeft: 0.5,
                                    paddingRight: 0.5,
                                },
                            }, children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { dataQA: "check_stock_size_title", size: cotopaxi_1.TextSize.SMALL, inline: true, emphasized: true, children: isOneSizeProduct ? t('pdp.size.onesize.txt') : size.code }) }) }, size.sku))) }) }), (0, jsx_runtime_1.jsx)("tbody", { children: productColors.map((color) => ((0, jsx_runtime_1.jsx)("tr", { children: productSizes.map((size) => {
                        var _a;
                        const availableStock = color.sizes[size.code] ? String(color.sizes[size.code].availableStock) : '-';
                        const sku = (_a = color.sizes[size.code]) === null || _a === void 0 ? void 0 : _a.idSku;
                        return ((0, jsx_runtime_1.jsx)("td", { children: (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { fill: true, dataQA: "check_stock_size", size: cotopaxi_1.ButtonSize.SMALL, variant: cotopaxi_1.ButtonVariant.TRANSPARENT_ALT, text: (0, jsx_runtime_1.jsx)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, inline: true, emphasized: sku === selectedSku, children: availableStock }), onClick: () => onCellClick(sku) }) }, `cell-${color.colorId}_${size.sku}`));
                    }) }, `row-${color.colorId}`))) })] }));
    return ((0, jsx_runtime_1.jsx)(cotopaxi_1.Container, { gutter: false, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Row, { clear: true, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { grid: 4, gutter: false, children: colorsTable }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Column, { grid: 8, gutter: false, children: skuTable })] }) }));
};
exports.AvailabilityInCurrentStoreTable = AvailabilityInCurrentStoreTable;
