"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailInformationHeadAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const andes_react_1 = require("@yonderland/andes-react");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const ReviewRatingAlpha_1 = require("../review-rating/ReviewRatingAlpha");
const ProductDetailInformationHeadAlpha = ({ productDetailTabs }) => {
    const detailInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDetailInformation)());
    const productDefaults = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const review = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductReview)());
    const { brand, description, title } = detailInformation;
    const { productCode } = productDefaults;
    return ((0, jsx_runtime_1.jsx)(andes_react_1.ConditionalWrapper, { condition: !!description, wrapper: children => ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", children: [children, (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { children: description })] })), children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { dataTestId: "product_name", variant: "body2XL", color: "emphasised", wordBreak: "break", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Link, { href: brand.relativePath, variant: "primary", children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { dataTestId: "product_brand", variant: "titleXL", children: brand.description }) }), ' ', title] }), review && ((0, jsx_runtime_1.jsx)(ReviewRatingAlpha_1.ReviewRatingAlpha, { productCode: productCode, review: review, productDetailTabs: productDetailTabs }))] }) }));
};
exports.ProductDetailInformationHeadAlpha = ProductDetailInformationHeadAlpha;
