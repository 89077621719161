"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useValidateAddress = void 0;
const tslib_1 = require("tslib");
const react_1 = require("react");
const addressFields_utils_1 = require("../../form/address-fields/addressFields.utils");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const useValidateAddress = ({ address, country, trigger, shouldValidate = true, isFormActive, }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.checkout);
    const [isValidAddress, setIsValidAddress] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        const validateForm = () => tslib_1.__awaiter(void 0, void 0, void 0, function* () {
            const isValid = yield (0, addressFields_utils_1.getAddressValidationSchema)({ country, t }).isValid(address);
            setIsValidAddress(isValid);
            if (!isValid) {
                yield trigger();
            }
        });
        if (address && shouldValidate) {
            validateForm();
        }
    }, [address, country, shouldValidate, isFormActive]);
    return { isValidAddress };
};
exports.useValidateAddress = useValidateAddress;
