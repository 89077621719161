"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductPlacementCopyAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const productPlacementCopyBoxAlpha_1 = require("../product-placement-copy-box/productPlacementCopyBoxAlpha");
const andes_react_1 = require("@yonderland/andes-react");
const ProductPlacementCopyAlpha = ({ ppcType }) => {
    const getPPCPositionsForSelectedColor = (0, ProductSelector_1.makeGetPPCForSelectedColorWithId)();
    const items = (0, react_redux_1.useSelector)((state) => getPPCPositionsForSelectedColor(state, ppcType));
    if (!(items === null || items === void 0 ? void 0 : items.length)) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(andes_react_1.StackBlock, { gap: "2", dataTestId: "product-placement-copy", children: items.map((item) => ((0, jsx_runtime_1.jsx)(productPlacementCopyBoxAlpha_1.ProductPlacementCopyBoxAlpha, { ppc: item }, item.name))) }));
};
exports.ProductPlacementCopyAlpha = ProductPlacementCopyAlpha;
