"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductImageHeight = exports.ProductImageWidth = exports.PRODUCT_SIZE_CHIP_INNER_PADDING = exports.MAX_NUMBER_OF_ARTICLES_IN_LISTER_PAGE_DEFAULT = exports.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM = exports.PRODUCT_URL_PREFIX = void 0;
exports.PRODUCT_URL_PREFIX = 'p';
exports.PRODUCT_FREDHOPPER_SEARCH_PARAM_PLATFORM = 'public_site';
exports.MAX_NUMBER_OF_ARTICLES_IN_LISTER_PAGE_DEFAULT = 48;
exports.PRODUCT_SIZE_CHIP_INNER_PADDING = 16;
var ProductImageWidth;
(function (ProductImageWidth) {
    ProductImageWidth[ProductImageWidth["THUMB"] = 65] = "THUMB";
    ProductImageWidth[ProductImageWidth["ADDITIONAL_TILE"] = 48] = "ADDITIONAL_TILE";
    ProductImageWidth[ProductImageWidth["ALTERNATIVE_TILE"] = 127] = "ALTERNATIVE_TILE";
    ProductImageWidth[ProductImageWidth["BASKET"] = 180] = "BASKET";
    ProductImageWidth[ProductImageWidth["LISTER"] = 316] = "LISTER";
    ProductImageWidth[ProductImageWidth["DETAIL"] = 550] = "DETAIL";
    ProductImageWidth[ProductImageWidth["ZOOM"] = 989] = "ZOOM";
})(ProductImageWidth || (exports.ProductImageWidth = ProductImageWidth = {}));
var ProductImageHeight;
(function (ProductImageHeight) {
    ProductImageHeight[ProductImageHeight["THUMB"] = 98] = "THUMB";
    ProductImageHeight[ProductImageHeight["ADDITIONAL_TILE"] = 72] = "ADDITIONAL_TILE";
    ProductImageHeight[ProductImageHeight["ALTERNATIVE_TILE"] = 184] = "ALTERNATIVE_TILE";
    ProductImageHeight[ProductImageHeight["BASKET"] = 279] = "BASKET";
    ProductImageHeight[ProductImageHeight["LISTER"] = 474] = "LISTER";
    ProductImageHeight[ProductImageHeight["DETAIL"] = 825] = "DETAIL";
    ProductImageHeight[ProductImageHeight["ZOOM"] = 1484] = "ZOOM";
})(ProductImageHeight || (exports.ProductImageHeight = ProductImageHeight = {}));
