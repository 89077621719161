"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCollageWishListButton = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const WishListActions_creators_1 = require("../../../redux/wishList/actions/WishListActions.creators");
const AddToWishlistButtonAlpha_1 = require("../add-to-wishlist-button/AddToWishlistButtonAlpha");
const WishListSelectors_1 = require("../../../redux/wishList/selectors/WishListSelectors");
const ProductCollageWishListButton = ({ wishListProduct }) => {
    const productColorIsInWishList = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetProductColorIsInWishList)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleToggleWishlist = () => {
        dispatch(WishListActions_creators_1.wishListActions.toggleProduct(wishListProduct));
    };
    return ((0, jsx_runtime_1.jsx)(AddToWishlistButtonAlpha_1.AddToWishlistButtonAlpha, { isProductInWishList: productColorIsInWishList, onToggle: handleToggleWishlist, wishListProduct: wishListProduct }));
};
exports.ProductCollageWishListButton = ProductCollageWishListButton;
