"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailsFields = void 0;
const tslib_1 = require("tslib");
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const common_components_1 = require("@as-react/common-components");
const react_i18next_1 = require("react-i18next");
const common_queries_1 = require("@as-react/common-queries");
const react_query_1 = require("@tanstack/react-query");
const FormField_1 = require("../../form/FormField");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const CustomerDetailsFields = () => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.checkout, i18nConstants_1.I18N_NAMESPACES.general]);
    const { defaultRequestParams, servicesEndpoint, lang } = (0, common_components_1.useAemConfig)();
    const { getTitles } = (0, common_queries_1.customerQueries)({ servicesEndpoint, defaultRequestParams });
    const { data: customerTitles } = (0, react_query_1.useQuery)(getTitles({ lang }));
    const titleOptions = (customerTitles === null || customerTitles === void 0 ? void 0 : customerTitles.options.map(title => ({ id: title.value, text: title.label }))) || [];
    const showMiddleName = defaultRequestParams.market === 'nl';
    const fieldRowOffset = showMiddleName ? { ['@xl']: 2, ['@2xl']: 1 } : { ['@xl']: 1 };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { columns: 12, gapY: 6, fullWidth: true, isQueryContainer: true, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: {
                    base: 8,
                    ['@sm']: 6,
                    ['@xl']: showMiddleName ? 4 : 3,
                    ['@2xl']: showMiddleName ? 3 : 3,
                }, rowOffset: 1, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "idTitle", required: true, children: (_a) => {
                        var _b = _a.fieldProps, { ref, onChange, value, disabled } = _b, options = tslib_1.__rest(_b, ["ref", "onChange", "value", "disabled"]), { error, controls } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.SelectAlpha, Object.assign({}, options, { input: {
                                id: 'idTitle',
                                label: t('checkout:checkout.customer.title.label'),
                                placeholder: t('checkout:checkout.customer.title.placeholder'),
                                required: true,
                                disabled,
                                error,
                            }, items: titleOptions, onSelect: value => {
                                onChange(value.id);
                                controls.validate();
                            }, value: titleOptions.length ? value : undefined })));
                    } }) }), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@xl']: 4, ['@2xl']: showMiddleName ? 3 : 4 }, rowOffset: fieldRowOffset, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "firstName", required: true, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: "firstName", maxLength: 50, required: true, label: t('checkout:checkout.customer.firstname.label') })));
                    } }) }), showMiddleName && ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { base: 12, ['@xl']: 3, ['@2xl']: 2 }, rowOffset: fieldRowOffset, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "middleName", required: true, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: "middleName", maxLength: 50, optionalText: t('checkout:checkout.customer.optional'), label: t('checkout:checkout.customer.middlename.label') })));
                    } }) })), (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: {
                    base: 12,
                    ['@xl']: 5,
                    ['@2xl']: showMiddleName ? 4 : 6,
                }, rowOffset: fieldRowOffset, children: (0, jsx_runtime_1.jsx)(FormField_1.FormField, { name: "lastName", required: true, children: (_a) => {
                        var _b = _a.fieldProps, { ref } = _b, options = tslib_1.__rest(_b, ["ref"]), { error } = _a;
                        return ((0, jsx_runtime_1.jsx)(andes_react_1.TextField, Object.assign({}, options, { error: error, id: "lastName", maxLength: 50, required: true, label: t('checkout:checkout.customer.lastname.label') })));
                    } }) })] }));
};
exports.CustomerDetailsFields = CustomerDetailsFields;
