"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCollage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const react_1 = require("react");
const ProductPreview_1 = require("../../../components/product-detail/product-preview/ProductPreview");
const cotopaxi_1 = require("@as/cotopaxi");
const andes_react_1 = require("@yonderland/andes-react");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const ProductCollageItem_1 = require("./ProductCollageItem");
const ProductPreviewLightBox_1 = require("../product-preview/product-preview-light-box/ProductPreviewLightBox");
const common_components_1 = require("@as-react/common-components");
const SHOWN_COLLAGE_ITEMS = 4;
const COLLAGE_TEST_ID = 'product_collage';
const ProductCollage = ({ videos }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const aemConfiguration = (0, common_components_1.useAemConfig)();
    const { productImageConfig, feature_toggles: { product_gender_icon: showGenderIcon, product_online_exclusive, product_new_label }, isShopLanding, } = aemConfiguration;
    const previewInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPreviewInformation)());
    const productDefaults = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const alt = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductImageAlt)());
    const dynamicSavingsInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetDynamicSavingsInformation)());
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    const showSustainabilityLabel = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductOurPlanet)());
    const newProduct = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetNewProduct)());
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const { genderFeatureId, productCode, productId } = productDefaults;
    const { images, priceOverlay } = previewInformation;
    const savingsPercentage = dynamicSavingsInformation === null || dynamicSavingsInformation === void 0 ? void 0 : dynamicSavingsInformation.productDiscountPercentage;
    const showOnlineExclusiveLabel = product_online_exclusive && previewInformation.webOnly;
    const showNewProductLabel = product_new_label && newProduct;
    const rawPopoverHandlers = (0, cotopaxi_1.usePopoverHandlers)();
    const popoverHandlers = Object.assign(Object.assign({}, rawPopoverHandlers), { anchorEl: rawPopoverHandlers.anchorEl || null });
    const { isSm, isMd } = (0, andes_react_1.useBreakpoints)();
    const [activeSlideIndex, setActiveSlideIndex] = (0, react_1.useState)(0);
    const [showAllMedia, setShowAllMedia] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        setActiveSlideIndex(0);
    }, [selectedColorId]);
    const mediaCollection = (0, ProductPreview_1.populateMediaCollection)(images, productCode, productImageConfig, videos || []);
    const wishListProduct = {
        colorId: selectedColorId,
        productCode: productCode,
        productId: parseInt(productId, 10),
        sku: selectedSku,
    };
    const handleClickCollageItem = (event, index) => {
        setActiveSlideIndex(index);
        popoverHandlers.handlePopoverOpen(event);
    };
    const productCollageItemProps = {
        wishListProduct,
        alt,
        showGenderIcon,
        genderFeatureId,
        savingsPercentage,
        priceOverlay,
        showNewProductLabel,
        showOnlineExclusiveLabel,
        showSustainabilityLabel,
        onClick: handleClickCollageItem,
    };
    const [shownMediaCollection, hiddenMediaCollection] = [
        mediaCollection.slice(0, SHOWN_COLLAGE_ITEMS),
        mediaCollection.slice(SHOWN_COLLAGE_ITEMS),
    ];
    const handleClickShowAll = () => {
        setShowAllMedia(true);
    };
    const onActiveSlideIndexChange = (newActiveSlideIndex) => {
        setActiveSlideIndex(newActiveSlideIndex);
    };
    return shownMediaCollection.length > 0 ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [shownMediaCollection.length === 1 ? ((0, jsx_runtime_1.jsx)(andes_react_1.Grid, { dataTestId: COLLAGE_TEST_ID, columns: {
                    xs: 2,
                    lg: 4,
                }, gap: 2, children: (0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: 2, offset: { lg: 2 }, children: (0, jsx_runtime_1.jsx)(ProductCollageItem_1.ProductCollageItem, Object.assign({ index: 0, item: shownMediaCollection[0], showLabels: true, showWishlistButton: !isShopLanding }, productCollageItemProps)) }) })) : ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockEnd: "4", children: (0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { dataTestId: COLLAGE_TEST_ID, columns: {
                        xs: 1,
                        lg: 2,
                    }, gap: 2, children: [shownMediaCollection.map((item, index) => ((0, jsx_runtime_1.jsx)(ProductCollageItem_1.ProductCollageItem, Object.assign({ index: index, item: item, showLabels: index === 0, showWishlistButton: !isShopLanding && index === (isSm || isMd ? 0 : 1) }, productCollageItemProps), index))), hiddenMediaCollection.length > 0 &&
                            (!showAllMedia ? ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { span: { lg: 2 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "3", children: (0, jsx_runtime_1.jsx)(andes_react_1.StackInline, { contentAlign: "center", children: (0, jsx_runtime_1.jsx)(andes_react_1.ButtonAlpha, { dataTestId: "product_collage_show_more", theme: "branded", variant: "secondary", size: "md", text: t('pdp.collage.show.more'), onClick: handleClickShowAll }) }) }) })) : (hiddenMediaCollection.map((item, index) => ((0, jsx_runtime_1.jsx)(ProductCollageItem_1.ProductCollageItem, Object.assign({ index: shownMediaCollection.length + index, item: item }, productCollageItemProps), index)))))] }) })), (0, jsx_runtime_1.jsx)(ProductPreviewLightBox_1.ProductPreviewLightBox, { activeSlideIndex: activeSlideIndex, alt: alt, mediaCollection: mediaCollection, onActiveSlideIndexChange: onActiveSlideIndexChange, popoverHandlers: popoverHandlers })] })) : null;
};
exports.ProductCollage = ProductCollage;
