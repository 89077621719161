"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDeliveryAddressCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const HomeDeliveryAddressForm_1 = require("./HomeDeliveryAddressForm");
const HomeDeliveryAddressCard = ({ checked, address, addressTitle, onClick, isEditing, onEdit, handleSubmit, handleCancel, isPending, error, dataTestId = 'hd_address', }) => {
    const showAddressForm = isEditing || !address;
    const onChange = () => {
        if (checked) {
            return;
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(address);
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.SelectionCard, { onClick: onChange, state: checked ? 'active' : 'resting', dataTestId: `${dataTestId}_option`, children: [(0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "media", children: (0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingBlockStart: "0.5", children: (0, jsx_runtime_1.jsx)(andes_react_1.RadioButton, { id: `address-${(address === null || address === void 0 ? void 0 : address.deliveryAddressId) || 'new'}`, name: "checkout-home-delivery-address", value: address === null || address === void 0 ? void 0 : address.deliveryAddressId, checked: checked, onChange: onChange }) }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "header", cellProps: { align: 'center', display: 'grid' }, children: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { truncate: true, children: addressTitle }) }), (0, jsx_runtime_1.jsx)(andes_react_1.SelectionCard.Cell, { section: "content", cellProps: {
                    align: 'center',
                }, children: (0, jsx_runtime_1.jsx)(HomeDeliveryAddressForm_1.HomeDeliveryAddressForm, { address: address, checked: checked, isPending: isPending, isEditing: showAddressForm, error: error, onSubmit: handleSubmit, onEdit: onEdit, onCancel: handleCancel }) })] }));
};
exports.HomeDeliveryAddressCard = HomeDeliveryAddressCard;
