"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GridTileContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const GeneralConstants_1 = require("../../constants/GeneralConstants");
const ProductTileVertical_1 = require("../product-tile/vertical/ProductTileVertical");
const common_components_1 = require("@as-react/common-components");
const RowBanner_1 = require("./row-banner/RowBanner");
const BannerTile_1 = require("./banner-tile/BannerTile");
const GridTilePlaceholder_1 = require("./grid-tile-placeholder/GridTilePlaceholder");
const react_redux_1 = require("react-redux");
const UrlSelectors_1 = require("../../redux/url/selectors/UrlSelectors");
const andes_react_1 = require("@yonderland/andes-react");
const react_1 = require("react");
const GRID_COLUMNS = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 4,
    ['2xl']: 4,
};
const shouldShowContentBlock = (itemsLength, activeBreakpoint, visibleInRow, shownContentBlocks) => {
    const itemsPerRow = GRID_COLUMNS[activeBreakpoint];
    const previousRowWithItems = visibleInRow - (1 + shownContentBlocks);
    const minimumItemsToFillPreviousRow = itemsPerRow * previousRowWithItems - itemsPerRow;
    return itemsLength > minimumItemsToFillPreviousRow;
};
const GridTileContainer = ({ isLoading, items, onTileClick, updateCompareList, onWishListToggle, contentBlocks, singleActiveSizeFilterKeyName, compareItems, }) => {
    const { feature_toggles, isShopLanding } = (0, common_components_1.useAemConfig)();
    const queryInformation = (0, react_redux_1.useSelector)((0, UrlSelectors_1.makeGetUrlQueryInformationState)());
    const { lister_colour_swatches_visible_mobile, lister_colour_swatches_visible_desktop } = feature_toggles;
    const { active: activeBreakpoint } = (0, andes_react_1.useBreakpoints)();
    const determineLazyLoadState = (index) => index < GeneralConstants_1.PLACEHOLDER_TILES.length
        ? common_types_1.LazyLoadOptions.NO_LAZY_LOAD
        : index < GeneralConstants_1.PLACEHOLDER_TILES.length * 1.5
            ? common_types_1.LazyLoadOptions.LAZY_PRE_LOAD
            : common_types_1.LazyLoadOptions.LAZY_LOAD;
    const shownContentBlocks = (0, react_1.useMemo)(() => contentBlocks
        .sort((a, b) => a.visibleInRow - b.visibleInRow)
        .reduce((acc, contentBlock) => {
        if ((!contentBlock.visibleOnPage || contentBlock.visibleOnPage === (queryInformation.page || 0) + 1) &&
            shouldShowContentBlock(items.length, activeBreakpoint, contentBlock.visibleInRow, acc.length)) {
            return [...acc, contentBlock];
        }
        return acc;
    }, []), [contentBlocks, queryInformation.page, items.length, activeBreakpoint]);
    const generateRowBanner = (contentBlock, index) => ((0, jsx_runtime_1.jsx)(andes_react_1.Grid.Cell, { rowOffset: contentBlock.visibleInRow, span: "full", children: (0, jsx_runtime_1.jsx)(RowBanner_1.RowBanner, { content: contentBlock.content }) }, `row-banner-item-${index}`));
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Grid, { id: common_types_1.LISTER_ID, dataTestId: common_types_1.LISTER_ID, columns: GRID_COLUMNS, gap: {
            xs: 4,
            sm: 6,
            lg: 8,
        }, children: [isLoading
                ? GeneralConstants_1.PLACEHOLDER_TILES.map((_, index) => (0, jsx_runtime_1.jsx)(GridTilePlaceholder_1.GridTilePlaceholder, {}, `grid-item-${index}`))
                : items.map((item, index) => {
                    const shouldLazyLoadImage = determineLazyLoadState(index);
                    if ((0, common_types_1.isBanner)(item)) {
                        const { image, url, alt } = item.images[0];
                        return ((0, jsx_runtime_1.jsx)(BannerTile_1.BannerTile, { lazy: shouldLazyLoadImage === common_types_1.LazyLoadOptions.LAZY_LOAD, url: url, image: image, alt: alt }, `grid-item-${index}`));
                    }
                    return ((0, jsx_runtime_1.jsx)(ProductTileVertical_1.ProductTileVertical, { product: item, onTileClick: onTileClick, onWishListToggle: onWishListToggle, updateCompareList: updateCompareList, compareItems: compareItems, shouldLazyLoadImage: shouldLazyLoadImage, alwaysShowSwatches: isShopLanding, showSwatchesOnDesktop: lister_colour_swatches_visible_desktop, showSwatchesOnMobile: lister_colour_swatches_visible_mobile, singleActiveSizeFilterKeyName: singleActiveSizeFilterKeyName, showSizesOnHover: !isShopLanding, addDataMonetateProductId: true }, `${item.productCode}${item.colourId}${index}`));
                }), shownContentBlocks.map(generateRowBanner)] }));
};
exports.GridTileContainer = GridTileContainer;
