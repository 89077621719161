"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProductCTA = void 0;
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const ActionEnums_1 = require("../enums/ActionEnums");
const BasketActions_creators_1 = require("../redux/basket/actions/BasketActions.creators");
const BasketSelector_1 = require("../redux/basket/selectors/BasketSelector");
const ProductSelector_1 = require("../redux/product/selectors/ProductSelector");
const WishListActions_creators_1 = require("../redux/wishList/actions/WishListActions.creators");
const WishListSelectors_1 = require("../redux/wishList/selectors/WishListSelectors");
const AnalyticsActions_creators_1 = require("../redux/analytics/actions/AnalyticsActions.creators");
const ProductDetailV2Container_1 = require("../containers/product/ProductDetailV2Container");
const useProductCTA = () => {
    const { isShopLanding, checkoutCc: clickAndCollectEnabled } = (0, common_components_1.useAemConfig)();
    const { selectedAdditionalProducts } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const basketValidationState = (0, react_redux_1.useSelector)((0, BasketSelector_1.makeGetBasketValidationState)());
    const productColorIsInWishList = (0, react_redux_1.useSelector)((0, WishListSelectors_1.makeGetProductColorIsInWishList)());
    const sizeCombinationNotExist = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeCombinationNotExist)());
    const { productCode, productId, unavailableOnline } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)());
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    const selectedSku = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedSku)());
    const { hdExcluded, ccExcluded } = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillment)());
    const fulfillmentBySize = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductFulfillmentBySize)());
    const priceInformation = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetPriceInformation)());
    const dispatchAddToBasket = (quantity) => {
        dispatch(selectedAdditionalProducts.length > 0
            ? BasketActions_creators_1.basketActions.createAddAdditionalProductsAction(selectedAdditionalProducts, quantity, ActionEnums_1.ProductAddToBasketLocations.PDP)
            : BasketActions_creators_1.basketActions.createAddAction(quantity, ActionEnums_1.ProductAddToBasketLocations.PDP, common_types_1.PDPVersion.V2));
    };
    const toggleWishList = (wishListProduct) => {
        dispatch(WishListActions_creators_1.wishListActions.toggleProduct(wishListProduct));
    };
    const dispatchOpenCC = () => {
        dispatch(BasketActions_creators_1.basketActions.createCCPopupOpenAction());
        dispatch(AnalyticsActions_creators_1.analyticsActions.showStoreStock());
    };
    const dispatchSizeForgotten = () => dispatch(BasketActions_creators_1.basketActions.createSizeForgottenShowAction());
    const wishListProduct = {
        colorId: selectedColorId,
        productCode: productCode,
        productId: parseInt(productId, 10),
        sku: selectedSku,
    };
    return {
        isShopLanding,
        clickAndCollectEnabled,
        dispatchAddToBasket,
        dispatchOpenCC,
        dispatchSizeForgotten,
        toggleWishList,
        productColorIsInWishList,
        wishListProduct,
        basketValidationState,
        sizeCombinationNotExist,
        unavailableOnline,
        selectedSku,
        hdExcluded,
        ccExcluded,
        fulfillmentBySize,
        priceInformation,
    };
};
exports.useProductCTA = useProductCTA;
