"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueChangeWatcher = void 0;
const react_1 = require("react");
const react_hook_form_1 = require("react-hook-form");
const ValueChangeWatcher = ({ fieldName, value, skipValidation, onChange, onError, }) => {
    const { control, setValue, clearErrors, getFieldState } = (0, react_hook_form_1.useFormContext)();
    const currentEmailValue = (0, react_hook_form_1.useWatch)({ control, name: fieldName });
    const { error } = getFieldState(fieldName);
    (0, react_1.useEffect)(() => {
        currentEmailValue !== value && setValue(fieldName, value, { shouldValidate: true });
    }, [value]);
    (0, react_1.useEffect)(() => {
        onChange && onChange(currentEmailValue);
    }, [currentEmailValue]);
    (0, react_1.useEffect)(() => {
        (error === null || error === void 0 ? void 0 : error.message) && (onError === null || onError === void 0 ? void 0 : onError(error.message));
    }, [error]);
    (0, react_1.useEffect)(() => {
        if (skipValidation) {
            clearErrors(fieldName);
        }
        else {
            setValue(fieldName, currentEmailValue, { shouldValidate: true });
        }
    }, [skipValidation]);
    return null;
};
exports.ValueChangeWatcher = ValueChangeWatcher;
