"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockAvailabilityPopupAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const cotopaxi_1 = require("@as/cotopaxi");
const i18nConstants_1 = require("../../constants/i18nConstants");
const AvailabilityInCurrentStoreTable_1 = require("./AvailabilityInCurrentStoreTable");
const AvailabilityInAllStoresTable_1 = require("./AvailabilityInAllStoresTable");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const StockAvailabilityPopupAlpha = ({ productCode, productSizes, currentStoreData = [], allStoresData, selectedSku, isOneSizeProduct, productBrand, productTitle, productImageCdnDomains, onCellClick, }) => {
    const { t } = (0, react_i18next_1.useTranslation)([i18nConstants_1.I18N_NAMESPACES.store]);
    const { anchorEl, handlePopoverOpen, handlePopoverClose } = (0, cotopaxi_1.usePopoverHandlers)();
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.Box, { children: [(0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { dataTestId: "check_stock", variant: "primary", theme: "neutral", iconLeft: { source: andes_icons_1.TShirtIcon, size: 'sm' }, text: t('store.show.available.stock'), onClick: handlePopoverOpen }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Popover, { type: cotopaxi_1.PopoverType.MODAL, anchorEl: anchorEl, title: t('store.check.stock.popup.title'), onClose: handlePopoverClose, children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { paddingInline: "8", paddingBlockEnd: "8", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { dataTestId: "check_stock_title", children: [productTitle, " - ", productBrand, " - ", productCode] }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", strong: true, children: t('store.check.stock.this.store') }), (0, jsx_runtime_1.jsx)(AvailabilityInCurrentStoreTable_1.AvailabilityInCurrentStoreTable, { productCode: productCode, productSizes: productSizes, productColors: currentStoreData, selectedSku: selectedSku, isOneSizeProduct: isOneSizeProduct, productImageCdnDomains: productImageCdnDomains, onCellClick: onCellClick }), (0, jsx_runtime_1.jsx)(andes_react_1.Divider, {}), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", children: t('store.select.size.txt') })] }) }), allStoresData && (allStoresData === null || allStoresData === void 0 ? void 0 : allStoresData.length) > 0 && ((0, jsx_runtime_1.jsx)(andes_react_1.Box, { backgroundColor: "quiet", padding: "8", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "4", children: [(0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", strong: true, children: t('store.check.stock.all.stores') }), (0, jsx_runtime_1.jsx)(AvailabilityInAllStoresTable_1.AvailabilityInAllStoresTable, { allStoresData: allStoresData })] }) }))] })] }));
};
exports.StockAvailabilityPopupAlpha = StockAvailabilityPopupAlpha;
