"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlternativeProductTileAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const common_types_1 = require("@as-react/common-types");
const common_components_1 = require("@as-react/common-components");
const common_utils_1 = require("@as-react/common-utils");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const ImageUtil_1 = require("../../../util/ImageUtil");
const ProductUtil_1 = require("../../../util/ProductUtil");
const asNumeral_1 = require("../../../vendors/asNumeral");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const ProductConstants_1 = require("../../../constants/ProductConstants");
const AlternativeProductTileAlpha = ({ product, onWishListToggle, updateCompareList, }) => {
    var _a;
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const { root, currency, isShopLanding, feature_toggles, productImageConfig } = (0, common_components_1.useAemConfig)();
    const { product_compare } = feature_toggles;
    const selectedColor = (0, common_utils_1.findColor)(product.colours, product.colourId);
    const productUrl = (0, ProductUtil_1.getProductDetailUrl)(product, root);
    const compareCheckboxId = `product-compare-alternative-${product.productCode}-${product.colourId}`;
    const { priceActual, pricePrevious } = (0, common_utils_1.generateCotopaxiPriceViewModel)({
        prices: selectedColor.prices,
        hasDiscount: selectedColor.hasDiscount,
    }, t, asNumeral_1.AsNumeral.getInstance().formatAsCurrency);
    const image = (0, ImageUtil_1.getPortraitImageSizeUrlByProductCode)(product.productCode, selectedColor.image, productImageConfig.cdnDomains, common_types_1.ProductImageSizesPortraitEnum.basket);
    const handleWishListClick = (e) => {
        e.stopPropagation();
        onWishListToggle({
            productCode: product.productCode,
            productId: product.productId,
            colorId: selectedColor.colourId,
        });
    };
    const handleCompareChange = () => {
        updateCompareList({
            brand: product.brand,
            color: selectedColor.colourId,
            image: selectedColor.image,
            productCode: product.productCode,
            selected: false,
            id: product.productId,
            title: product.title,
            gender: product.genderCode,
            url: productUrl,
            colorDescription: selectedColor.description,
            collapsedComparePopup: true,
        });
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.ProductTileHorizontal, { product: {
            image,
            alt: product.title,
            title: product.title,
            brand: product.brand,
            currency,
            priceActual,
            pricePrevious,
        }, children: [(0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "media", children: (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Image, { lazyLoad: true, width: ProductConstants_1.ProductImageWidth.ALTERNATIVE_TILE, height: ProductConstants_1.ProductImageHeight.ALTERNATIVE_TILE, url: productUrl }) }), (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Cell, { section: "content", children: (0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "2", itemsAlign: "start", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "2", wrap: "nowrap", itemsAlign: "start", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Box, { flex: { grow: 1 }, children: (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Title, { brandOnTheSameLine: true, lineClamp: 2, url: productUrl }) }), !isShopLanding && ((0, jsx_runtime_1.jsx)(andes_react_1.IconButton, { onClick: handleWishListClick, variant: "plain", size: "sm", icon: {
                                        source: selectedColor.inWishList ? andes_icons_1.HeartFillIcon : andes_icons_1.HeartIcon,
                                        size: 'sm',
                                        animation: selectedColor.inWishList ? 'pulse' : undefined,
                                    } }))] }), (0, jsx_runtime_1.jsxs)(andes_react_1.Link, { variant: "tertiary", theme: "neutral", href: productUrl, children: [!!product.productRating && ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "1", itemsAlign: "center", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Rating, { rating: product.productRating, readOnly: true }), (0, jsx_runtime_1.jsx)(andes_react_1.Link, { size: "sm", variant: "secondary", theme: "neutral", href: productUrl, children: (_a = product.productReviews) !== null && _a !== void 0 ? _a : 0 })] })), (0, jsx_runtime_1.jsx)(andes_react_1.ProductTile.Price, {}), (0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { variant: "bodyS", color: "subtle", children: [product.colours.length, " ", t('lister.swatch.counter', { count: product.colours.length })] })] }), product_compare && ((0, jsx_runtime_1.jsx)(andes_react_1.Checkbox, { id: compareCheckboxId, name: compareCheckboxId, value: compareCheckboxId, label: t('productCompare.pdp.checkbox'), checked: selectedColor.isCompareProduct || false, onChange: handleCompareChange }))] }) })] }));
};
exports.AlternativeProductTileAlpha = AlternativeProductTileAlpha;
